import React, { useEffect, useState } from 'react'
import './myorder.scss'
import LayoutOne from '../../layouts/LayoutOne'
import { getCall } from '../../api/apiService'
import { Button, Collapse, Table, Tag } from 'antd'
import { capitalizeFirstLetter, colors } from '../../helpers/utils'

import {
  PDFDownloadLink,
  View,
  Text,
  Image,
  Document,
  Page,
  StyleSheet,
} from '@react-pdf/renderer'

const logo = process.env.PUBLIC_URL + '/assets/img/logo/logo.webp'

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    gap: '5px',
    height: 'auto',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: 180,
    height: 80,
    display: 'flex',
  },
  table: {
    display: 'table',
    border: '1px solid',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: 'auto', flexDirection: 'row' },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10,
    boxSizing: 'border-box',
    padding: 5,
  },
  productName: {
    fontSize: 8,
  },
  productFirstColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalCost: {
    display: 'flex',
    gap: '5px',
    flexDirection: 'column',
    marginLeft: 'auto',
    width: '20%',
    marginTop: 10,
  },
  totalCostLabel: {
    fontSize: 12,
  },
  totalCostValue: {
    fontSize: 14,
    fontWeight: 600,
  },
  orderEstimate: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 20,
  },
  accountDetails: {
    boxSizing: 'border-box',
    padding: '30px',
  },
  accountDetailsLabel: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
  },
  accountDetail: {
    fontSize: 14,
    fontWeight: 400,
    color: '#737373',
    marginBottom: 4,
  },
  addressDetails: {
    boxSizing: 'border-box',
    padding: '30px',
  },
  addressDetailsLabel: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 10,
  },
  addressDetail: {
    fontSize: 14,
    fontWeight: 400,
    color: '#737373',
    marginBottom: 4,
  },
  termsAndConditions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginLeft: 40,
  },
  tc: {
    fontWeight: 600,
    fontSize: 14,
    textDecoration: 'underline',
  },
  tccolumn: {
    fontSize: 12,
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
})

const ProductView = ({ product }) => {
  const [productDetail, setProduct] = useState([])
  useEffect(() => {
    async function getSingleProduct() {
      const response = await getCall(`/products/${product.master_id}`)
      setProduct((prevState) => response[0])
    }
    getSingleProduct()
  }, [product.master_id])

  return (
    <View style={styles.tableRow}>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{productDetail?.master_code}</Text>
        <View style={styles.productFirstColumn}>
          <Text style={styles.productName}>
            Product Name : {productDetail?.product_name}
          </Text>
        </View>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>
          {
            productDetail?.variants?.filter(
              (item) => item.variant_id == product.variant_id
            )[0]?.color_group
          }
        </Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{product?.unit_price}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{product?.quantity}</Text>
      </View>
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>{product?.total_price}</Text>
      </View>
    </View>
  )
}

const OrderTitle = ({ item }) => {
  const [arrowDownSrc, setarrowDownSrc] = useState(
    process.env.PUBLIC_URL + '/assets/loaders/arrowdown/arrow-down.webp'
  )
  const [startDownload, setStartDownload] = useState(false)

  return (
    <div className="order-title">
      <span>Order Id : {item._id}</span>
      <Button
        className="downloadEstimate"
        onMouseOver={() =>
          setarrowDownSrc(
            (prevState) =>
              process.env.PUBLIC_URL +
              '/assets/loaders/arrowdown/arrow-down.gif'
          )
        }
        onMouseLeave={() =>
          setarrowDownSrc(
            (prevState) =>
              process.env.PUBLIC_URL +
              '/assets/loaders/arrowdown/arrow-down.webp'
          )
        }
        onClick={() => setStartDownload(true)}
      >
        <img src={arrowDownSrc} height={25} width={25} alt='arrowDownSrc Img' />
        {startDownload ? (
          <PDFDownloadLink
            document={
              <Document>
                <Page size="A4" style={styles.page}>
                  <View style={styles.table}>
                    <Image src={logo} style={styles.image} />
                    <Text style={styles.orderEstimate}>ORDER ESTIMATE</Text>
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Master Code</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Product Color</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Unit Price (AED)</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Quantity</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>Total Price (AED)</Text>
                      </View>
                    </View>
                    {item?.products?.map((item) => (
                      <ProductView product={item} />
                    ))}
                    <View style={styles.totalCost}>
                      <Text style={styles.totalCostLabel}>
                        Total Cost + VAT (5%)
                      </Text>
                      <Text style={styles.totalCostValue}>
                        AED{' '}
                        {(
                          parseFloat(item?.total) +
                          parseFloat((item?.total * 5) / 100)
                        ).toFixed(2)}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.termsAndConditions}>
                    <Text style={styles.tc}>Terms & Conditions</Text>
                    <View style={styles.tccolumn}>
                      <Text>1 . Minimum Order Quantity(MOQ) - 25 Pieces.</Text>
                      <Text>2 . Logo required in EPS or AI vector format.</Text>
                      <Text>
                        3 . Printing/embroidery (additional charges as per
                        artworks provided)
                      </Text>
                      <Text>
                        4 . Payment: 50% advance, balance upon delivery.
                      </Text>
                      <Text>5 . Stocks subject to availability.</Text>
                      {item.delivery_day && (
                        <Text>
                          6 . Delivery Working Days - {item.delivery_day}{' '}
                          (additional charges + courier mode)
                        </Text>
                      )}
                      <Text>
                        7 . Sample Pre Working Days from the PO issued
                      </Text>
                      <Text>
                        8 . Production 10 - 12 working days from the date of
                        sample approval
                      </Text>
                    </View>
                  </View>
                  <View style={styles.accountDetails}>
                    <Text style={styles.accountDetailsLabel}>
                      Account Details
                    </Text>
                    <View>
                      <View>
                        <Text style={styles.accountDetail}>
                          Account Name : G CUBE INTERNATIONAL FZCO
                        </Text>
                        <Text style={styles.accountDetail}>
                          Bank Name : MASHREQ BANK PSC
                        </Text>
                        <Text style={styles.accountDetail}>
                          Account Number : 019101317860
                        </Text>
                        <Text style={styles.accountDetail}>
                          IBAN Number : AE750330000019101317860
                        </Text>
                        <Text style={styles.accountDetail}>
                          Swift code : BOMLAEAD
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.addressDetails}>
                    <Text style={styles.addressDetailsLabel}>Address</Text>
                    <View>
                      <Text style={styles.addressDetail}>
                        G CUBE INTERNATIONAL FZCO , {'\n'}
                        DUBAI COMMERCITY BUILDING NO 2, {'\n'}
                        LEVEL 1 UMM RAMOOL DUBAI - UAE
                      </Text>
                      <Text style={styles.addressDetail}>
                        Corporate Tax no 104187800800001
                      </Text>
                    </View>
                  </View>
                </Page>
              </Document>
            }
            fileName="estimate.pdf"
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Creating Estimate...' : 'Download Estimate'
            }
          </PDFDownloadLink>
        ) : (
          <span>Click To Load Estimate</span>
        )}
      </Button>
    </div>
  )
}

const ProductRow = ({ product }) => {
  const [productDetails, setProductDetails] = useState([])

  useEffect(() => {
    async function getDetails() {
      const response = await getCall(`/products/${product.master_id}`)
      setProductDetails((prevState) => response[0])
    }
    getDetails()
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      <a href={`/product/${productDetails.master_id}`} target="_blank" rel='noreferrer' >
        <img alt='productDetailsImg'
          src={
            productDetails?.variants?.filter(
              (variant) => variant.variant_id == product.variant_id
            )[0].digital_assets[0].url
          }
          height={50}
          width={50}
        />
      </a>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          gap: '5px',
        }}
      >
        {product?.modified?.length > 0 && (
          <Tag className="personalized-product">Personalized Product</Tag>
        )}
        <a 
          className="prod-name"
          href={`/product/${productDetails.master_id}`}
          target="_blank" rel='noreferrer'
        >
          {productDetails.product_name}
        </a>
        <a
          className="prod-mc"
          href={`/product/${productDetails.master_id}`}
          target="_blank" rel='noreferrer'
        >
          {productDetails.master_code}
        </a>
        <a
          className="prod-sd"
          href={`/product/${productDetails.master_id}`}
          target="_blank" rel='noreferrer'
        >
          {productDetails.short_description}
        </a>
      </div>
    </div>
  )
}

const OrderRow = ({ data }) => {
  let productColumn = [
    {
      title: 'Product Details',
      dataIndex: 'product_row',
      key: 'product_row',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Product Unit Price',
      dataIndex: 'unit_price',
      key: 'unit_price',
    },
    {
      title: 'Product Total Price',
      dataIndex: 'total_price',
      key: 'total_price',
    },
  ]

  let dataSource = [
    {
      product: (
        <Table
          columns={productColumn}
          dataSource={data.products.map((item) => ({
            product_row: <ProductRow product={item} />,
            quantity: item.quantity,
            unit_price: <span>AED {parseFloat(item.unit_price)}</span>,
            total_price: (
              <span>AED {parseFloat(item.total_price).toFixed(2)} </span>
            ),
          }))}
          pagination={false}
        />
      ),
      total: (
        <span>
          AED {parseFloat(data?.total).toFixed(2)}
          <br />
          {data.products.map((item) => {
            if (item.modified) {
              return ``
            }
          })}
        </span>
      ),
      status: (
        <Tag color={colors[data.status.toLowerCase()]}>
          {capitalizeFirstLetter(data.status)}
        </Tag>
      ),
      modified: (
        <div>
          {data.products.map((item) => {
            if (item.modified) {
              return item.modified.map((modifiedItem, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                  }}
                >
                  <span>
                    Printing Position : {modifiedItem.printing_position}
                  </span>
                  {index < item.modified.length - 1 && <br />}
                </div>
              ))
            }
          })}
        </div>
      ),
    },
  ]

  const columns = [
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Total Price',
      dataIndex: 'total',
      key: 'total',
    },
  ]

  useEffect(() => {
    if (data?.products[0]?.modified) {
      columns.splice(1, 0, {
        title: 'Printing Position Details',
        dataIndex: 'modified',
        key: 'modified',
      })
    }
  }, [])

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      className="table-product"
    />
  )
}

const MyOrders = () => {
  const [orders, setOrders] = useState([])
  const [trolleySrc, settrolleySrc] = useState(
    process.env.PUBLIC_URL + '/assets/loaders/shop/trolley.webp'
  )

  useEffect(() => {
    async function getOrders() {
      const response = await getCall('/orders/my_orders')
      setOrders((prevState) => response.data)
    }
    getOrders()
  }, [])

  function createArray() {
    if (orders.length == 0) {
      return []
    } else {
      const items = orders.map((item, index) => ({
        key: index,
        label: <OrderTitle item={item} />,
        children: <OrderRow data={item} />,
      }))
      return items
    }
  }

  return (
    <LayoutOne headerTop="visible">
      <div className="my-orders-page">
        {orders.length == 0 ? (
          <div className="no-orders-found">
            <img alt='noProductsFound Img'
              src={process.env.PUBLIC_URL + '/assets/no-products-found.webp'}
              height={250}
              width={250}
            />
            <span>
              "Looks like you're on a clean slate! Ready to kick off your
              shopping spree? Place a new order now and let the excitement
              begin!"
            </span>
            <Button
              className="continue-shop-btn"
              href="/shop"
              onMouseEnter={() =>
                settrolleySrc(
                  (prevState) =>
                    process.env.PUBLIC_URL + '/assets/loaders/shop/trolley.gif'
                )
              }
              onMouseLeave={() =>
                settrolleySrc(
                  (prevState) =>
                    process.env.PUBLIC_URL + '/assets/loaders/shop/trolley.webp'
                )
              }
            >
              <span>Continue Shopping</span>
              <img src={trolleySrc} height={25} width={25} alt='trolleySrc Img' />
            </Button>
          </div>
        ) : (
          <div>
            <Collapse items={createArray()} defaultActiveKey={['0']} />
          </div>
        )}
      </div>
    </LayoutOne>
  )
}

export default MyOrders
