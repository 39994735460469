import { notification } from "antd";
import axios from "axios";
import cogoToast from "cogo-toast";

// export const BACKEND_BASE_URL = `http://localhost:8000/api`;
// export const BACKEND_BASE_URL = `http://43.205.116.124:8000/api`;
export const BACKEND_BASE_URL = `https://backend.geniearabia.com/api`;

export const apiCallWithoutHeader = async (url, payload) => {
  try {
    const response = await axios.post(BACKEND_BASE_URL + url, payload);
    return response.data;
  } catch (error) {
    cogoToast.error(error.response.data.message, { position: "top-right" });
  }
};
export const PutapiCall = async (url, payload) => {
  try {
    const response = await axios.put(BACKEND_BASE_URL + url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    cogoToast.error("Something Went Wrong", { position: "bottom-right" });
  }
};

export const postCall = async (url, payload) => {
  try {
    const response = await axios.post(BACKEND_BASE_URL + url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data?.message === "Token Expired or Not authorized") {
      cogoToast.error(<span>Please Click Here To login</span>, {
        position: "top-right",
      });
    } else {
      cogoToast.error("Something Went Wrong1", { position: "top-center" });
    }
  }
};
export const postFormCall = async (url, data) => {
  try {
    const formData = new FormData();
    // eslint-disable-next-line
    Object.keys(data).map((obj) => {
      formData.append(obj, data[obj]);
    });
    return new Promise((resolve, reject) => {
      axios
        .post(BACKEND_BASE_URL + url, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          }
        })
        .catch((err) => reject(err));
    });
  } catch (error) {
    console.error(error);
  }
};

export const getCall = async (url, params = {}) => {
  try {
    // Replace 'undefined' with an empty string if present in the URL
    if (url.includes("undefined")) {
      url = url.replace("undefined", "");
    }
    const response = await axios.get(BACKEND_BASE_URL + url, {
      params: params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    cogoToast.error("Something Went Wrong", { position: "bottom-right" });
  }
};

export const deleteCall = async (url, payload) => {
  try {
    const response = await axios.delete(BACKEND_BASE_URL + url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      data: payload,
    });
    return response.data;
  } catch (error) {
    notification.error({
      message: "Something Went Wrong When Deleting",
      description: "Please Contact Admin If This Issue Persist",
    });
  }
};

export const deleteCallWithoutPayload = async (url, params = {}) => {
  try {
    const response = await axios.delete(BACKEND_BASE_URL + url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    cogoToast.error("Error When Registering User", {
      position: "top-right",
    });
  }
};

export const updateCart = async (url, payload) => {
  try {
    const response = await axios.put(BACKEND_BASE_URL + url, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const initiatePayment = async (paymentPayload) => {
  try {
    const response = await axios.post(
      "/mer/v2.0/checkout/link", // Use relative URL
      paymentPayload, // Use the payload defined above
      {
        headers: {
          "X-Paymennt-Api-Key": process.env.REACT_APP_PAYMENT_GATEWAY_API_KEY,
          "X-Paymennt-Api-Secret":
            process.env.REACT_APP_PAYMENT_GATEWAY_API_KEY_SECRET,
        },
      }
    );

    // Check if the response contains redirectUrl
    if (response.data && response.data.result.redirectUrl) {
      // Open the redirectUrl in a new tab
      window.open(response.data.result.redirectUrl);
    }

    return response.data;
  } catch (error) {
    console.error("Error initiating payment:", error);
    throw error; // rethrow the error if you want to handle it elsewhere
  }
};
