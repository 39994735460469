import React from "react";
import "./terms-and-conditions.scss";
import HeaderOne from "../../wrappers/header/HeaderOne";
import FooterOne from "../../wrappers/footer/FooterOne";

const ReturnPolicy = () => {
  return (
    <>
      <HeaderOne />
      <div className="terms-and-condition-genie-arabia">
        <div className="static-content-area">
          <h1>Return Policy</h1>
          <ul>
            <li>
              We accept returns within 7 to 10 days of delivery for items that are unused, undamaged, and in their original packaging.
            </li>
            <li>
              To initiate a return, please contact our customer support team at sales@geniearabia.com. Provide your order number and reason for the return.
            </li>
            <li>
              Once your return request is approved, you will receive instructions on how to return the item.
            </li>
            <li>
              The cost of return shipping will be borne by the customer unless the return is due to a defect or error on our part.
            </li>
            <li>
              Refunds will be processed within 7 to 10 business days after we receive the returned item. The refund will be credited to the original mode of payment.
            </li>
            <li>
              Certain items, such as personalized or customized products, are not eligible for returns unless they are defective or damaged.
            </li>
          </ul>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default ReturnPolicy;
