import { Suspense, lazy, useEffect } from 'react'
import ScrollToTop from './helpers/scroll-top'
import { BrowserRouter as Router, Routes, Route ,useNavigate} from 'react-router-dom'
import { getCall } from './api/apiService'
import { setProducts } from './store/slices/product-slice'
import { useDispatch } from 'react-redux'
import Personalize from './components/personalize/Personalize'
import MyOrders from './components/order/MyOrder'
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions'
import PrivacyPolicy from './components/TermsAndConditions/PrivacyPolicy'
import arrowUp from './components/main-landing-page/assets/arrow-up.webp'
import MadeInIndiaProducts from './pages/MadeInIndiaProducts'
import MadeInIndiaProductsGrid from './pages/MadeInIndiaProductsGrid'
import ProductDeatils from './pages/ProductDeatils'
import CancellationPolicy from './components/TermsAndConditions/CancellationPolicy'
import ReturnPolicy from './components/TermsAndConditions/ReturnPolicy'
import DeliveryPolicy from './components/TermsAndConditions/DeliveryPolicy'

const HomeFashionThree = lazy(() => import('./pages/home/HomeFashionThree'))

// shop pages
const ShopGridStandard = lazy(() => import('./pages/shop/ShopGridStandard'))
const PrintingTechnique = lazy(() => import('./pages/other/PrintingTechnique'))
const PadPrintings = lazy(() => import('./pages/other/gridIt'))
const ScreenPrinting = lazy(() =>
  import('./pages/other/printingTechRotes/screenPrinting')
)
const DigitalPrinting = lazy(() =>
  import('./pages/other/printingTechRotes/digitalPrinting')
)
const ScreenTransfer = lazy(() =>
  import('./pages/other/printingTechRotes/screenTransfer')
)
const LaserEngraving = lazy(() =>
  import('./pages/other/printingTechRotes/laserEngraving')
)
const DigitalTransfer = lazy(() =>
  import('./pages/other/printingTechRotes/digitalTransfer')
)
const Sublimation = lazy(() =>
  import('./pages/other/printingTechRotes/sublimation')
)
const CeramicTransfer = lazy(() =>
  import('./pages/other/printingTechRotes/ceramicTransfer')
)
const Embroidery = lazy(() =>
  import('./pages/other/printingTechRotes/embrodiory')
)
const ButtonPrinting = lazy(() =>
  import('./pages/other/printingTechRotes/buttonPriting')
)
const Doming = lazy(() => import('./pages/other/printingTechRotes/Doming'))
const DigitalLabel = lazy(() =>
  import('./pages/other/printingTechRotes/digitalLabel')
)
const GigitalInlay = lazy(() =>
  import('./pages/other/printingTechRotes/DigitalInlay')
)
const Debossing = lazy(() =>
  import('./pages/other/printingTechRotes/Debossing')
)


// product pages
const Product = lazy(() => import('./pages/shop-product/Product'))
const ProductTabRight = lazy(() =>
  import('./pages/shop-product/ProductTabRight')
)
// other pages
// const About = lazy(() => import('./pages/other/About'))
const Contact = lazy(() => import('./pages/other/Contact'))
const MyAccount = lazy(() => import('./pages/other/MyAccount'))
const LoginRegister = lazy(() => import('./pages/other/LoginRegister'))
const ForgotPassword = lazy(() => import('./pages/other/ForgotPassword'))

const Cart = lazy(() => import('./pages/other/Cart'))
const Wishlist = lazy(() => import('./pages/other/Wishlist'))

// eslint-disable-next-line
const NotFound = lazy(() => import('./pages/other/NotFound'))

export const MoveToTop = () => {
  return (
    <div className="move-to-top">
      <img
        src={arrowUp}
        height={50}
        width={50}
        title="Move To Top"
        alt="Move to top IMG"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        }}
      />
    </div>
  )
}

const App = () => {
  const dispatch = useDispatch()

  function NotFound() {
    const navigate = useNavigate();
  
    useEffect(() => {
      navigate(process.env.PUBLIC_URL, { replace: true });
    }, [navigate]);
  
    return null;
  }
  
  useEffect(() => {
    async function getProducts() {
      const response = await getCall(`/products?limit=20`)
      dispatch(setProducts(response))
    }
    getProducts()
    // eslint-disable-next-line 
  }, [])

  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <img
                  src={process.env.PUBLIC_URL + `/assets/loaders/genie-gif.gif`}
                  alt="loading"
                  height={50}
                  width={35}
                />
              </div>
            </div>
          }
        >
          <Routes>
            <Route
              path={process.env.PUBLIC_URL + '/login-register'}
              element={<LoginRegister />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/forgot-Password'}
              element={<ForgotPassword />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/'}
              element={<HomeFashionThree />}
            />
            <Route path={process.env.PUBLIC_URL + '/cart'} element={<Cart />} />
            <Route
              path={process.env.PUBLIC_URL + '/:product_name'}
              element={<Product />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/wishlist'}
              element={<Wishlist />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/printingTechnique'}
              element={<PrintingTechnique />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/printingTechnique/Padprinting'}
              element={<PadPrintings />}
            />

            <Route
              path={
                process.env.PUBLIC_URL + '/printingTechnique/ScreenPrinting'
              }
              element={<ScreenPrinting />}
            />

            <Route
              path={
                process.env.PUBLIC_URL + '/printingTechnique/DigitalPrinting'
              }
              element={<DigitalPrinting />}
            />

            <Route
              path={
                process.env.PUBLIC_URL + '/printingTechnique/ScreenTransfer'
              }
              element={<ScreenTransfer />}
            />

            <Route
              path={
                process.env.PUBLIC_URL + '/printingTechnique/LaserEngraving'
              }
              element={<LaserEngraving />}
            />

            <Route
              path={
                process.env.PUBLIC_URL + '/printingTechnique/DigitalTransfer'
              }
              element={<DigitalTransfer />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/printingTechnique/Sublimation'}
              element={<Sublimation />}
            />

            <Route
              path={
                process.env.PUBLIC_URL + '/printingTechnique/CeramicTransfer'
              }
              element={<CeramicTransfer />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/printingTechnique/Embroidery'}
              element={<Embroidery />}
            />

            <Route
              path={
                process.env.PUBLIC_URL + '/printingTechnique/ButtonPrinting'
              }
              element={<ButtonPrinting />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/printingTechnique/Doming'}
              element={<Doming />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/printingTechnique/DigitalLabel'}
              element={<DigitalLabel />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/printingTechnique/DigitalInlay'}
              element={<GigitalInlay />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/printingTechnique/Debossing'}
              element={<Debossing />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/my-account'}
              element={<MyAccount />}
            />
            {/* <Route
              path={process.env.PUBLIC_URL + '/about'}
              element={<About />}
            /> */}
            <Route
              path={process.env.PUBLIC_URL + '/contact'}
              element={<Contact />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/personalize/:id'}
              element={<Personalize />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/my-orders'}
              element={<MyOrders />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/terms-and-conditions'}
              element={<TermsAndConditions />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/cancellation-policy'}
              element={<CancellationPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/delivery-policy'}
              element={<DeliveryPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/return-policy'}
              element={<ReturnPolicy />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/privacy-policy'}
              element={<PrivacyPolicy />}
            />
            <Route
              path={
                process.env.PUBLIC_URL +
                '/shop/:category1?/:category2?/:category3?'
              }
              element={<ShopGridStandard />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/product/:id'}
              element={<ProductTabRight />}
            />

            <Route
              path={process.env.PUBLIC_URL + '/Products-list'}
              element={<MadeInIndiaProducts />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/Made-In-India-Products'}
              element={<MadeInIndiaProductsGrid />}
            />
            <Route
              path={process.env.PUBLIC_URL + '/Product-Details'}
              element={<ProductDeatils />}
            />
           <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
    </Router>
  )
}

export default App
