import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { capitalizeWords } from "../../helpers/product";
import { fetchWishlistItems } from "../../store/slices/wishlist-slice";
import { Button, Collapse, InputNumber, Tag } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { postCall } from "../../api/apiService";
import cogoToast from "cogo-toast";
import { Modal } from "react-bootstrap";
import axios from "axios";
import emailjs from "emailjs-com";
import { notification } from "antd";

let onlyNumberRegex = new RegExp(/^[0-9]*$/);

export async function handleWishlist(productId, dispatch) {
  const response = await postCall("/Wishlist/toggle_wishlist", {
    productId: productId,
  });
  if (!!response) {
    fetchWishlistItems(dispatch);
    cogoToast.success(response.message, { position: "top-right" });
  }
}

const ProductDescriptionInfoNew = ({
  product,
  cartItems,
  setVariantIndex,
  variantIndex,
}) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [variantId, setVariantId] = useState(product.variants[0].variant_id);
    // eslint-disable-next-line
  const [variantImages, setVariantImages] = useState(
    product.variants[0].digital_assets
  );
  const [quantity, setQuantity] = useState(1);
    // eslint-disable-next-line
  const [alreadyInCart, setAlreadyInCart] = useState(false);
  const [heartSrc, setHeartSrc] = useState(
    process.env.PUBLIC_URL + "/assets/loaders/heart/heart.webp"
  );
  
      // eslint-disable-next-line
  const [personalizeSrc, setPersonalizeSrc] = useState(
    process.env.PUBLIC_URL + "/assets/loaders/personalize/personalize.webp"
  );
      // eslint-disable-next-line
  const [shoppingBagSrc, setShoppingBagSrc] = useState(
    process.env.PUBLIC_URL + "/assets/loaders/shopping-bag/shopping-bag.webp"
  );

  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [inputs, setInputs] = useState({});
  const [selectedproduct, setselectedproduct] = useState(null);
      // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setselectedproduct(null);
  };

  const handleWhatsAppMessage= (product) => {
    let url = `https://api.whatsapp.com/send?phone=971585076157&text=Hello%20I%20Saw%20this%20Product%20-%20${product.variants[0].sku}%20in%20Your%20Website%20May%20I%20know%20more%20Details%20About%20This`
    window.open(url)
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    inputs.product_name = product.variants[0].sku ? product.variants[0].sku : product.product_name;
    try {
      await axios.post(`${process.env.REACT_APP_SEO_API_BASE}/Orders/`, inputs);
      setShow(false);
      window.alert('Thank You for the Enquiry We will Contact You Soon !')
      await handleSendMessage(event, inputs); // Call handleSendMessage here
      window.location.href = 'https://geniearabia.com/';
    } catch (err) {
      console.error(err);
    }
  };


  const handleSendMessage = async (event,input) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const templateParams = {
        to_email: "sales@geniearabia.com",
        from_name: input.customer_name,
        from_email: input.email_id,
        message: `New Enquiry Placed By the Customer ${input.customer_name} for the Product - ${input.product_name} of the Quantity ${input.quantity} and Customer Contact Number is ${input.phone_number}`,
      };

      await emailjs.send(
        "service_3k4xvds",
        "template_frkmpix",
        templateParams,
        "e4m4A-fjcnnQnKa_p"
      );

      notification.success({
        message: "Email Sent Successfully",
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error sending email",
      });
    }

    setIsLoading(false);
  };

  const handlePlaceOrder = (product) => {
    setselectedproduct(product);
    handleShow();
  };


  return (
    <div className="product-details-content ml-70">
      <h1 style={{ fontSize: "24px" }}>
      </h1>
      <div className="pro-details-list">
        <span>{product.product_name}</span>
        <p>{capitalizeWords(product.short_description)}</p>
        <span>{product?.long_description}</span>
        <div>Master Code : {product.master_code}</div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
            width: "80%",
          }}
        >
        </div>
        <div>Material : {capitalizeWords(product.material)}</div>
        <div>
          Available Quantity : {/* {product.quantity}{" "} */}  MOQ Min - 25 Pieces
          {product.quantity === 0 ? (
            <Tag className="stockcomingsoontag">Stocks Coming Soon</Tag>
          ) : (
            ""
          )}
        </div>
        <div className="variant-product-price">
          Product Price<sup>(excl. shipping)</sup> :{" "}
          <span>AED - {product.price ? product.price : "Based Upon Request"}</span>
        </div>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        />
      </div>
      {product.quantity > 0 ? (
        <>
          <div className="add-to-cart-section-sp">
            <InputNumber
              placeholder="Enter Quantity"
              className="quantity-input"
              type="number"
              value={quantity}
              onChange={(e) => {
                if (onlyNumberRegex.test(e)) {
                  if (e > product.quantity) {
                    setQuantity(
                      (prevState) => product.quantity
                    );
                  } else {
                    if (e < 0) {
                      setQuantity((prevState) => 1);
                    } else {
                      setQuantity((prevState) => e);
                    }
                  }
                } else {
                  setQuantity((prevState) => 1);
                }
              }}
              controls={false}
            />
            <Button
              className="add-item-to-wishlist-btn"
              onClick={() => handleWhatsAppMessage(product)}
              title={"Add To Wishlist"}
              onMouseEnter={() =>
                setHeartSrc(
                  process.env.PUBLIC_URL + "/assets/loaders/wpicon.png"
                )
              }
              onMouseLeave={() =>
                setHeartSrc(
                  process.env.PUBLIC_URL + "/assets/loaders/wpicon.png"
                )
              }
            >
              <img src={heartSrc} height={25} width={25} alt="heartSrc Img" />
              <span>Chat Now</span>
            </Button>
          </div>
          <Button
            className="add-to-cart-btn"
            onClick={() => handlePlaceOrder(product)}
          >
            <img
              src={shoppingBagSrc}
              height={25}
              width={25}
              alt="shoppingBagSrc Img"
            />
            <span>Quick Quote</span>
          </Button>
          {/*           {!alreadyInCart && (
            <Button
              className="personalize-btn"
              onMouseEnter={() =>
                setPersonalizeSrc(
                  process.env.PUBLIC_URL +
                    "/assets/loaders/personalize/personalize.gif"
                )
              }
              onMouseLeave={() =>
                setPersonalizeSrc(
                  process.env.PUBLIC_URL +
                    "/assets/loaders/personalize/personalize.webp"
                )
              }
              onClick={() => {
                 if (userLoggedIn()) {
                  navigate(`/personalize/${product.master_id}`, {
                    state: {
                      product: product,
                      variantIndex: variantIndex,
                      variantColorCode: variantColorCode,
                      stock: quantity,
                      totalStock: product.quantity,
                    },
                  });
                 } else {
                  cogoToast.error(
                    <span>
                      Please Login To Perform this Action
                    </span>,
                    {
                      position: "top-right",
                    }
                  );
                }
              }}
            >
              <img src={personalizeSrc} height={25} width={25} alt="personalizeSrc IMG" />
              <span>Add To Cart Printed</span>
            </Button>
          )} */}
        </>
      ) : (
        <>
          <Button
            className="add-item-to-wishlist-btn"
            onClick={() => handleWishlist(product._id, dispatch)}
            title={"Add To Wishlist"}
            onMouseEnter={() =>
              setHeartSrc(
                process.env.PUBLIC_URL + "/assets/loaders/heart/heart.gif"
              )
            }
            onMouseLeave={() =>
              setHeartSrc(
                process.env.PUBLIC_URL + "/assets/loaders/heart/heart.webp"
              )
            }
            style={{
              width: "100%",
              height: "35px",
              marginBottom: "10px",
            }}
          >
            <img src={heartSrc} height={25} width={25} alt="heartSrc Img" />
            <span>Save Product</span>
          </Button>
          <Button disabled className="outof-stock">
            Product Out Of Stock
          </Button>
        </>
      )}
      <div className="model_box">
        <Modal
          show={show}
          data={selectedproduct}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Submit Order Enquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Product Name"
                  defaultValue={
                    selectedproduct && selectedproduct.variants[0].sku
                      ? selectedproduct.variants[0].sku
                      : selectedproduct
                      ? selectedproduct.product_name
                      : ""
                  }
                  name="product_name"
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Name"
                  name="customer_name"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Phone Number"
                  name="phone_number"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Your Email ID"
                  name="email_id"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group mt-3">
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Enter Product Quantity"
                  name="quantity"
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="btn btn-success mt-4">
                Submit Order Enquiry
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

ProductDescriptionInfoNew.propTypes = {
  cartItems: PropTypes.array,
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.shape({}),
  wishlistItem: PropTypes.shape({}),
  setVariantIndex: PropTypes.any,
  variantIndex: PropTypes.number,
};

export default ProductDescriptionInfoNew;
