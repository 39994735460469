import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import "./../../assets/scss/_search.scss";
import MobileMenu from "../../components/header/MobileMenu";
import { CiHeart ,CiMenuBurger } from "react-icons/ci";
import { PiShoppingCartThin } from "react-icons/pi";
import { Button, Dropdown, Menu, Select,  Badge , Tabs, Drawer } from "antd";
import { getCall } from "../../api/apiService";

import { useNavigate } from "react-router";
import { userLoggedIn } from "../../helpers/user";
import { Link } from "react-router-dom";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { CiUser } from "react-icons/ci";
import { useSelector } from "react-redux";

import "./mobile-menu.scss";
import AdvanceFilter from "./advancefilter/AdvanceFilter";
import { formatCategory } from "../../helpers/product";

let categoryImages = {
  "Apparel & Accessories":
    process.env.PUBLIC_URL + "/assets/category/ddc/apparel-accessories.webp",
  "Bags & Travel":
    process.env.PUBLIC_URL + "/assets/category/ddc/bags-travel.webp",
  "Christmas & Winter":
    process.env.PUBLIC_URL + "/assets/category/ddc/christmas-winter.webp",
  "Eating & Drinking":
    process.env.PUBLIC_URL + "/assets/category/ddc/eating-drinking.webp",
  "Kids & Games":
    process.env.PUBLIC_URL + "/assets/category/ddc/kids-games.webp",
  "Office & Writing":
    process.env.PUBLIC_URL + "/assets/category/ddc/office-writing.webp",
  "Premiums & Tools":
    process.env.PUBLIC_URL + "/assets/category/ddc/premiums-tools.webp",
  "Sports & Recreation":
    process.env.PUBLIC_URL + "/assets/category/ddc/sports-recreation.webp",
  "Technology & Accessories":
    process.env.PUBLIC_URL + "/assets/category/ddc/technology-accessories.webp",
  "Textile SOLO Group":
    process.env.PUBLIC_URL + "/assets/category/ddc/textile-solo-group.webp",
  "Umbrellas & Rain garments":
    process.env.PUBLIC_URL +
    "/assets/category/ddc/umbrellas-rain-garments.webp",
  "Wellness & Care":
    process.env.PUBLIC_URL + "/assets/category/ddc/wellness-care.webp",
};

const MenuContent = ({ category }) => {
  return (
    <Menu
      style={{
        background: "#fff",
        border: "1px solid #cccccc90",
        borderRadius: "4px",
      }}
      mode="inline"
    >
      {category.map((singleCategory, index) => (
        <Menu.SubMenu title={singleCategory.category_level1} key={`${index}_1`}>
          {singleCategory.category_level2.map((item) => (
            <Menu.ItemGroup
              title={item.category_level2}
              key={item.category_level2}
            >
              {item.category_level3.map((cl3) => (
                <Menu.Item key={cl3.category_level3}>
                  <Link
                    to="/shop"
                    state={{
                      category_level_1: singleCategory.category_level1,
                      category_level_2: item.category_level2,
                      category_level_3: cl3.category_level3,
                    }}
                  >
                    {cl3.category_level3}
                  </Link>
                </Menu.Item>
              ))}
            </Menu.ItemGroup>
          ))}
        </Menu.SubMenu>
      ))}
    </Menu>
  );
};

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}) => {
  const navigate = useNavigate();

  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [activeKey, setActiveKey] = useState(0);
  // eslint-disable-next-line
  const [searchSelect, setSearchSelect] = useState(false);
  const [categoryLevel, setCategoryLevel] = useState([]);
  // eslint-disable-next-line
  const { cartItems } = useSelector((state) => state.cart);
  const [apiCallLoading, setApiCallLoading] = useState(false);
  // eslint-disable-next-line
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const [enterSearch, setEnterSearch] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);

  const [search, setsearch] = useState([]);
  // eslint-disable-next-line
  const [like, setlike] = useState("");

  // eslint-disable-next-line
  const [accountImgSrc, setAccountImgSrc] = useState(
    process.env.PUBLIC_URL + "/assets/loaders/account/account.webp"
  );
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getCategories = useCallback(async () => {
    const res = await getCall("/products/list_filters");
    const sortedArray = res?.filters?.sort((a, b) =>
      a.category_level1.localeCompare(b.category_level1)
    );
    setCategoryLevel(sortedArray);
    // eslint-disable-next-line
  }, [categoryLevel]);
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line
  }, [like]);

  const tabContent = (category) => {
    category.category_level2.sort((a, b) => {
      return a.category_level2?.localeCompare(b.category_level2);
    });

    category.category_level2.forEach((category) => {
      category.category_level3.sort((a, b) => {
        return a.category_level3?.localeCompare(b.category_level3);
      });
    });

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            background: "#cccccc10",
            minHeight: "65vh",
            width: "45vw",
          }}
        >
          <div
            style={{
              boxSizing: "border-box",
              padding: "20px",
              display: "flex",
              width: "100%",
              gap: "15px",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {category.category_level2.map(
              (item, index) =>
                item.category_level2 &&
                item?.category_level3[0].category_level3 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      width: "25%",
                    }}
                    key={`${index}__2`}
                  >
                    <strong>{item.category_level2}</strong>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      {item?.category_level3.map((cl3, index) => (
                        <Link
                          key={`${category.category_level1}___${index}`}
                          to={`/shop/${formatCategory(
                            category.category_level1
                          )}/${formatCategory(
                            item.category_level2
                          )}/${formatCategory(cl3.category_level3)}`}
                          state={{
                            category_level_1: category.category_level1,
                            category_level_2: item.category_level2,
                            category_level_3: cl3.category_level3,
                          }}
                          onClick={() => setShowDropdown(false)}
                        >
                          <p>{cl3.category_level3}</p>
                        </Link>
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <img
            alt="category_level"
            src={categoryImages[category.category_level1]}
            height={250}
            width={250}
          />
          <Link
            to={"/shop"}
            state={{
              category_level_1: category?.category_level1,
            }}
          >
            <Button className="shopNowBtn">Shop Now</Button>
          </Link>
        </div>
      </div>
    );
  };

  const productDropdown = (
    <>
      <Tabs
        style={{
          background: "#fff",
          boxSizing: "border-box",
          border: "1px solid #cccccc90",
          borderRadius: "4px",
          paddingRight: "10px",
          paddingTop: "10px",
          boxShadow: "0 0 400px 400px #00000070",
        }}
        centered
        onMouseLeave={() => setShowDropdown((prevState) => false)}
        tabPosition={"left"}
        activeKey={activeKey}
        items={categoryLevel?.map((singlecategory, index) => ({
          label: (
            <Link
              key={`${singlecategory.category_level1}_${index}_${index + 1}`}
              to={`/shop/${formatCategory(singlecategory.category_level1)}`}
              state={{
                category_level_1: singlecategory.category_level1,
              }}
              onClick={() => setShowDropdown(false)}
            >
              <span
                onMouseOver={() => setActiveKey(index)}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <p>{singlecategory.category_level1}</p>
              </span>
            </Link>
          ),
          key: index,
          children: tabContent(singlecategory),
        }))}
      />
    </>
  );

  const handleSearch = async (e) => {
    setApiCallLoading((prevState) => true);
    const res = await getCall("/products/search?like=" + e);
    setsearch((prevState) =>
      res?.products?.map((item) => ({
        label: (
          <Link
            to={`/product/${formatCategory(item.short_description)}?id=${
              item.master_id
            }`}
            key={item.master_id}
          >
            <div className="search-product-container">
              <div className="search-img">
                <img
                  alt="digital_assets Img"
                  width="50"
                  height="50"
                  src={item.variants[0].digital_assets[0].url}
                />
              </div>
              <div className="search-content">
                <p>{item.product_name}</p>
                <p>{item.master_code}</p>
              </div>
            </div>
          </Link>
        ),
        text: item.product_name,
      }))
    );
    setsearch((prevState) => [
      ...prevState,
      ...res?.filters?.map((cl, index) => ({
        label: (
          <span
            key={`${index}${cl.category_level1}`}
            onClick={() =>
              navigate("/shop", {
                state: {
                  category_level_1: cl.category_level1,
                  category_level_2: cl.category_level2,
                  category_level_3: cl.category_level3,
                },
              })
            }
            item={`${cl.category_level1}_${index}1`}
          >
            {cl.category_level2} &gt; {cl.category_level3}
          </span>
        ),
        text: "",
      })),
    ]);
    setEnterSearch((prevState) => [...res.filters]);
    setApiCallLoading((prevState) => false);
  };

  // eslint-disable-next-line
  function handleOnLogoutClick() {
    localStorage.clear();
    navigate("/login-register");
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && search.length === 1) {
      navigate(search[0]?.label?.props?.href);
    }
    if (e.key === "Enter" && enterSearch.length > 1) {
      navigate("/shop", {
        state: {
          category_level_2: enterSearch
            ?.map((item) => item.category_level2)
            .filter((value, index, self) => {
              return self.indexOf(value) === index;
            }),
          category_level_1: enterSearch
            ?.map((item) => item.category_level1)
            .filter((value, index, self) => {
              return self.indexOf(value) === index;
            }),
          category_level_3: enterSearch
            ?.map((item) => item.category_level3)
            .filter((value, index, self) => {
              return self.indexOf(value) === index;
            }),
        },
      });
    }
  };

  return (
    <header
      className={clsx(
        "header-area clearfix",
        headerBgClass,
        headerPositionClass
      )}
    >
      <div
        className={clsx(
          headerPaddingClass,
          "sticky-bar header-res-padding clearfix",
          scroll > headerTop && "stick"
        )}
      >
        <div
          className={"container-fluid"}
          style={{
            display: "flex",
            alignItems: "center",
            boxSizing: "border-box",
            padding: "5px",
            borderBottom: "1px solid #cccccc",
          }}
        >
          <div className="genie-arabia-header">
            <div className="head-section">
              <div className="ham-burger-section">
                <CiMenuBurger
                  onClick={() => showDrawer()}
                  style={{ transform: "scale(1.5)" }}
                />
              </div>
              <a href="/" className="logo-mobile">
                <img
                  alt="favicon"
                  src={process.env.PUBLIC_URL + `/favicon.ico`}
                  height={60}
                  width={40}
                />
              </a>
              <a
                href="/"
                className="normal-logo"
                style={{ marginLeft: "30px" }}
              >
                {/* <img
                  className="normal-logoImg"
                  alt="logoImg"
                  src={process.env.PUBLIC_URL + `/assets/img/logo/logo.webp`}
                  // style={{
                  //   transform: 'scale(0.045)',
                  // }}
                /> */}
                <img
                  className="lazyload normal-logoImg"
                  data-src={
                    process.env.PUBLIC_URL + `/assets/img/logo/logo.webp`
                  }
                  src={process.env.PUBLIC_URL + `/assets/img/logo/logo.webp`}
                  alt="logoImg"
                />
              </a>
              <div
                className="searchwholeshop"
                style={{ width: "50%", marginLeft: "85px" }}
              >
                <label for="Search"></label>
                <Select
                  id="Search"
                  showSearch
                  placeholder="Search the whole shop"
                  defaultActiveFirstOption={false}
                  style={{
                    width: "80%",
                    height: "40px",
                  }}
                  onSearch={handleSearch}
                  onChange={(e) => handleSearch(e)}
                  suffixIcon={
                    apiCallLoading ? (
                      <LoadingOutlined spin />
                    ) : (
                      <CloseOutlined
                        onClick={() => setSearchSelect((prevState) => false)}
                        style={{
                          fontSize: "12px",
                          transform: "scale(0.8)",
                          cursor: "pointer",
                        }}
                      />
                    )
                  }
                  className="select-search"
                  filterOption={false}
                  notFoundContent={null}
                  options={search}
                  onKeyDown={(e) => (!apiCallLoading ? handleKeyPress(e) : "")}
                />
              </div>
              <div className="logo-section">
                <div className="advancefilter"> 
                  <img
                    alt="advancefilterImage"
                    src={
                      process.env.PUBLIC_URL + "/assets/icon/advancefilter.webp"
                    }
                    height={25}
                    width={25}
                    title="Advance Filters"
                    onClick={() => setShowAdvanceFilter((prevState) => true)}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </div>
                <span></span>
                {showAdvanceFilter && (
                  <AdvanceFilter
                    showModal={showAdvanceFilter}
                    setShowModal={setShowAdvanceFilter}
                  />
                )}

               {userLoggedIn() ? (
                  <>
                    <Badge count={wishlistItems.length} className="wishlist">
                      <CiHeart
                        title="Wishlist"
                        onClick={() => navigate("/wishlist")}
                      />
                    </Badge>
                    <Badge count={cartItems.length}>
                      <PiShoppingCartThin
                        title="Shopping Cart"
                        onClick={() => navigate("/cart")}
                      />
                    </Badge>

                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item>
                            <Link to={"/my-account"}>My Account</Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link to={"/wishlist"}>My Wishlist</Link>
                          </Menu.Item>
                          <Menu.Item>
                            <Link to={"/my-orders"}>My Orders</Link>
                          </Menu.Item>
                          <Menu.Item onClick={handleOnLogoutClick}>
                            Logout
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <CiUser title="Account" />
                    </Dropdown>
                  </>
                ) : (
                  <>
                  <Link to="/login-register">
                    <Button
                      className="loginBtn"
                      onMouseOver={() =>
                        setAccountImgSrc(
                          process.env.PUBLIC_URL +
                            "/assets/loaders/account/account.gif"
                        )
                      }
                      onMouseLeave={() =>
                        setAccountImgSrc(
                          process.env.PUBLIC_URL +
                            "/assets/loaders/account/account.webp"
                        )
                      }
                    >
                      <img
                        alt="accountImg"
                        src={accountImgSrc}
                        height={25}
                        width={25}
                        onMouseOver={(e) =>
                          (e.target.src =
                            process.env.PUBLIC_URL +
                            "/assets/loaders/account/account.gif")
                        }
                        onMouseLeave={(e) =>
                          (e.target.src =
                            process.env.PUBLIC_URL +
                            "/assets/loaders/account/account.webp")
                        }
                      />
                      <span>Login</span>
                    </Button>
                  </Link>
                  </>
                )}
              </div>
            </div>
            <div className="static-content-links">
              <ul>
                <li>
                  <Link to="/" className="list-item">
                    Home
                  </Link>
                </li>
                <li>
                  <Dropdown
                    placement="bottomRight"
                    overlay={productDropdown}
                    open={showDropdown}
                    onMouseEnter={() => setShowDropdown((prevState) => true)}
                  >
                    <span className="products-menu">Products</span>
                  </Dropdown>
                </li>
                <li>
                  <Link to="/shop" className="list-item">
                    Shop
                  </Link>
                </li>

{/*                 <li>
                  <Link to="/Products-list" className="list-item">
                    Made In India
                  </Link>
                </li> */}

                <li>
                  <Link
                    to="https://online.flippingbook.com/view/751447315/"
                    className="list-item"
                    target="_blank"
                  >
                    Catalogue
                  </Link>
                </li>

                <li>
                  <Link to="/printingTechnique" className="list-item">
                    Printing Techniques
                  </Link>
                </li>

                <li>
                  <Link to="/contact" className="list-item">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="searchthewholeshopmobile">
          <div className="mobileshop">
            <Select
              showSearch
              placeholder="Search the whole shop"
              defaultActiveFirstOption={false}
              style={{
                width: "100%",
                height: "40px",
              }}
              onSearch={handleSearch}
              onChange={(e) => handleSearch(e)}
              suffixIcon={
                apiCallLoading ? (
                  <LoadingOutlined spin />
                ) : (
                  <CloseOutlined
                    onClick={() => setSearchSelect((prevState) => false)}
                    style={{
                      fontSize: "12px",
                      transform: "scale(0.8)",
                      cursor: "pointer",
                    }}
                  />
                )
              }
              className="select-search"
              filterOption={false}
              notFoundContent={null}
              options={search}
              onKeyDown={(e) => (!apiCallLoading ? handleKeyPress(e) : "")}
            />
          </div>
        </div>

        {open && (
          <Drawer title="Menu" onClose={onClose} open={open} placement={"left"}>
            <MenuContent category={categoryLevel} />
            <Menu>
{/*               <Menu.Item>
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + `/Products-list`,
                  }}
                >
                  Made In India Products
                </Link>
              </Menu.Item> */}
              <Menu.Item>
                <a href="https://online.flippingbook.com/view/751447315/">
                  Catalogue
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href="/contact">Contact Us</a>
              </Menu.Item>
            </Menu>
          </Drawer>
        )}
        <MobileMenu />
      </div>
    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
