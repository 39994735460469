import React, { useState, useEffect, useCallback } from "react";
import "./advance-filter.scss";
import { Button, InputNumber, Modal, Select } from "antd";
import { getCall } from "../../../api/apiService";
import { Link } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";

const AdvanceFilter = ({ showModal, setShowModal }) => {
  const [crossSrc, setCrossSrc] = useState(
    process.env.PUBLIC_URL + "/assets/loaders/cross/cross.webp"
  );
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [colors, setColors] = useState([]);
  const [stock, setStock] = useState(0);
  const [searchSrc, setSearchSrc] = useState(
    process.env.PUBLIC_URL + "/assets/loaders/search/search.webp"
  );
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const getCategories = useCallback(async () => {
    const res = await getCall("/products/list_filters");
    const sortedArray = res?.sort((a, b) =>
      a.category_level1.localeCompare(b.category_level1)
    );
    setCategoryFilter(sortedArray);
    setCategoryOptions(
      sortedArray.map((item) => ({
        label: item.category_level1,
        value: item.category_level1,
      }))
    );
  }, [categoryFilter]);
  useEffect(() => {
    const getColors = async () => {
      await getCall("/products/list_colors", {}).then((res) => {
        setColors(() => res);
      });
    };
    getColors();
    getCategories();
  }, []);

  function handleCategoryClick(item) {
    setSelectedCategory((prevState) => item);
  }

  function handleColorClick(color) {
    if (selectedColors.includes(color)) {
      setSelectedColors((prevState) =>
        prevState.filter((circleColor) => circleColor != color)
      );
    } else {
      setSelectedColors((prevState) => [...prevState, color]);
    }
  }

  return (
    <Modal
      open={showModal}
      onCancel={() => setShowModal((prevState) => false)}
      cancelText="Clear Search"
      closeIcon={
        <img alt="cross Img"
          src={crossSrc}
          onMouseOver={() =>
            setCrossSrc(
              (prevState) =>
                process.env.PUBLIC_URL + "/assets/loaders/cross/cross.gif"
            )
          }
          onMouseLeave={() =>
            setCrossSrc(
              (prevState) =>
                process.env.PUBLIC_URL + "/assets/loaders/cross/cross.webp"
            )
          }
          height={25}
          width={25}
          title="Close Advance Search"
        />
      }
      footer={
        <Link
          to={"/shop"}
          state={{
            selected_category: selectedCategory,
            selected_color: selectedColors,
            stock: stock,
          }}
        >
          <Button
            className="advance-search"
            onMouseOver={() =>
              setSearchSrc(
                (prevState) =>
                  process.env.PUBLIC_URL + "/assets/loaders/search/search.gif"
              )
            }
            onMouseLeave={() =>
              setSearchSrc(
                (prevState) =>
                  process.env.PUBLIC_URL + "/assets/loaders/search/search.webp"
              )
            }
          >
            <span>Search</span>
            <img src={searchSrc} alt="searchImage" height={25} width={25} />
          </Button>
        </Link>
      }
    >
      <div>
        <h4>Advanced Search</h4>
        <div className="advance-search-area">
          <div className="advance-search-item">
            <h5>By Category</h5>
            <div className="input-area">
              <Select
                mode="multiple"
                allowClear
                placeholder="Select Categories"
                options={categoryOptions}
                onChange={handleCategoryClick}
              />
            </div>
          </div>
          <div className="advance-search-item">
            <h5>Colors</h5>
            <div className="input-area color-area">
              {colors.map((color) => (
                <div
                  className="color-circle"
                  style={{
                    background: color,
                  }}
                  title={color.toUpperCase()}
                  onClick={() => handleColorClick(color)}
                >
                  {selectedColors.includes(color) && (
                    <CheckOutlined
                      style={{
                        transform: "scale(0.8)",
                        color: "#000000",
                        position: "absolute",
                      }}
                      className="check-outline"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="advance-search-item">
            <h5>Stock</h5>
            <div className="input-area">
              <InputNumber
                placeholder="Enter Stock"
                onChange={(value) => setStock(value)}
                max={10000}
                style={{
                  width: 150,
                }}
                controls={false}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AdvanceFilter;
