import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { EffectFade, Thumbs } from "swiper";
import AnotherLightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Swiper, { SwiperSlide } from "../../components/swiper";

const ProductImageGalleryNew = ({ product, variantIndex }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [rightArrow, setRightArrow] = useState(process.env.PUBLIC_URL + '/assets/loaders/right/right.webp')
  const [index, setIndex] = useState(-1);
  const slides = product?.variants[variantIndex].digital_assets.map(
    (img, i) => ({
      src: process.env.PUBLIC_URL + img.url,
      key: i,
    })
  );

  // swiper slider settings
  const gallerySwiperParams = {
    spaceBetween: 10,
    loop: true,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    thumbs: { swiper: thumbsSwiper },
    modules: [EffectFade, Thumbs],
  };

  const thumbnailSwiperParams = {
    onSwiper: setThumbsSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: false,
    slideToClickedSlide: true,
    navigate: {
      nextEl: ".custom-next-button",
      prevEl: ".custom-prev-button",
    },
  };

  return (
    <Fragment>
      <div className="product-large-image-wrapper">
        {product?.variants[variantIndex]?.digital_assets?.length ? (
          <>
            <Swiper options={gallerySwiperParams}>
              {product.variants[variantIndex].digital_assets?.map(
                (single, key) => (
                  <SwiperSlide key={key}>
                    <button
                      className="lightgallery-button"
                      onClick={() => setIndex(key)}
                    >
                      <i className="pe-7s-expand1"></i>
                    </button>
                    <div className="single-image">
                      <img
                        src={process.env.PUBLIC_URL + single.url}
                        className="img-fluid"
                        alt="single-img"
                      />
                    </div>
                  </SwiperSlide>
                )
              )}
              <AnotherLightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
                plugins={[Thumbnails, Zoom, Fullscreen]}
              />
            </Swiper>
          </>
        ) : null}
      </div>
      <div className="product-small-image-wrapper mt-15">
        {product?.variants[variantIndex].digital_assets?.length ? (
          <>
            {product?.variants[variantIndex].digital_assets?.length >= 4 && (
              <div
                className="custom-next-button"
                onClick={() => {
                  thumbsSwiper?.slideNext();
                }}
                onMouseOver={() =>
                  setRightArrow(
                    (prevState) =>
                      process.env.PUBLIC_URL + "/assets/loaders/right/right.gif"
                  )
                }
                onMouseLeave={() =>
                  setRightArrow(
                    (prevState) =>
                      process.env.PUBLIC_URL + "/assets/loaders/right/right.webp"
                  )
                }
              >
                <img src={rightArrow} height={25} width={25} alt="rightArrow Img" />
              </div>
            )}
            {product?.variants[variantIndex].digital_assets?.length >= 4 && (
              <div
                className="custom-prev-button"
                onClick={() => thumbsSwiper?.slidePrev()}
                onMouseOver={() =>
                  setRightArrow(
                    (prevState) =>
                      process.env.PUBLIC_URL + "/assets/loaders/right/right.gif"
                  )
                }
                onMouseLeave={() =>
                  setRightArrow(
                    (prevState) =>
                      process.env.PUBLIC_URL + "/assets/loaders/right/right.webp"
                  )
                }
              >
                <img alt="rightArrow Img"
                  src={rightArrow}
                  height={25}
                  width={25}
                  style={{
                    transform: "rotate(180deg)",
                  }}
                />
              </div>
            )}
            <Swiper options={thumbnailSwiperParams}>
              {product.variants[variantIndex].digital_assets.map(
                (single, key) => (
                  <SwiperSlide key={key}>
                    <div className="single-image">
                      <img
                        src={process.env.PUBLIC_URL + single.url}
                        className="img-fluid"
                        alt="single-img"
                      />
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </>
        ) : null}
      </div>
    </Fragment>
  );
};

ProductImageGalleryNew.propTypes = {
  product: PropTypes.shape({}),
  variantIndex: PropTypes.number,
};

export default ProductImageGalleryNew;
