import React, { useState, useRef, useEffect } from 'react'
import './_personalize.scss'
import {
  Button,
  Collapse,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Modal,
  Steps,
  Upload,
} from 'antd'
import { EditOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import Draggable from 'react-draggable'
import html2canvas from 'html2canvas'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  postCall,
} from '../../api/apiService'
import axios from 'axios'
import HeaderOne from '../../wrappers/header/HeaderOne'
import { BACKEND_BASE_URL } from '../../api/apiService'
import FooterOne from '../../wrappers/footer/FooterOne'
import { getAEDPrice } from '../../helpers/product'
import cogoToast from 'cogo-toast'
import { addToCart, deleteAllFromCart } from '../../store/slices/cart-slice'
import { useDispatch, useSelector } from 'react-redux'

const ImageEditorComponent = ({
  selectedPrintingPosition,
  variantColorCode,
  selectedTechnique,
  requestedStock,
  totalCostValue,
  setTotalCostValue,
  setImageFileList,
  index,
  setModified,
  modified,
  selectedPrintTechnique,
  setSelectedPrintTechnique,
}) => {
  const [removingBg, setRemovingBg] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [proxyImage, setProxyImage] = useState(null)
  const [fileList, setFileList] = useState([])
  const [printingPrice, setPrintingPrice] = useState(0)
  const [downloadLink, setDownloadLink] = useState()
  const [logoHeight, setLogoHeight] = useState(45)
  const [imageSaved, setImageSaved] = useState(false)
  const [logoWidth, setLogoWidth] = useState(45)

  const [newPositionLeft, setNewPositionLeft] = useState(0)
  const [newPositionTop, setNewPositionTop] = useState(0)
  const editorRef = useRef(null)

  useEffect(() => {
    let printingCost
    selectedPrintingPosition.printing_techniques
      .filter((item) => item.id == selectedPrintTechnique)[0]
      ?.var_costs[0]?.scales.forEach((vc) => {
        if (
          parseInt(vc.minimum_quantity.replace('.', '')) <=
          parseInt(requestedStock)
        ) {
          printingCost = vc.price
        }
      })
    setPrintingPrice((prevState) =>
      (getAEDPrice(printingCost) * requestedStock).toFixed(2)
    )
  }, [requestedStock, selectedPrintTechnique])

  useEffect(() => {
    if (fileList.length > 0) {
      if (downloadLink != undefined) {
        setModified((prevState) => [
          ...prevState,
          {
            printing_position: selectedPrintingPosition?.position_id,
            printing_technique: selectedTechnique?.ptp,
            modified_image: downloadLink,
            uploaded_image: fileList,
          },
        ])
      }
    }
  }, [fileList, downloadLink])

  const handleDragStop = (e, ui) => {
    const editorRect = editorRef.current.getBoundingClientRect()
    const newPositionLeft = ui.x / editorRect.width
    const newPositionTop = ui.y / editorRect.height
    setNewPositionTop((prevState) => newPositionTop)
    setNewPositionLeft((prevState) => newPositionLeft)
  }

  useEffect(() => {
    async function getProxyImage() {
      const response = await axios.post(
        `${BACKEND_BASE_URL}/products/proxy_image`,
        {
          image_url: selectedPrintingPosition?.images.find(
            (image) => image.variant_color === variantColorCode
          ).print_position_image_with_area,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
          },
        }
      )

      const imageData = response.data.data

      const binaryData = atob(imageData)
      const uint8Array = new Uint8Array(binaryData.length)

      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i)
      }

      const blob = new Blob([uint8Array], { type: 'image/jpeg' })
      const imageUrl = URL.createObjectURL(blob)

      setProxyImage((prevState) => imageUrl)
    }
    getProxyImage()
  }, [])

  const captureSnapshot = async (download = false) => {
    const divToCapture = editorRef.current

    try {
      const backgroundImage = new Image()
      backgroundImage.src = proxyImage

      backgroundImage.onload = function () {
        html2canvas(divToCapture, {
          allowTaint: true,
          useCORS: true,
          background: backgroundImage,
        }).then((canvas) => {
          const imageData = canvas.toDataURL('image/png')
          const downloadLink = document.createElement('a')
          downloadLink.href = imageData
          downloadLink.download = 'snapshot_image.png'
          document.body.appendChild(downloadLink)
          if (download) {
            downloadLink.click()
          }
          setDownloadLink((prevState) => imageData)
          document.body.removeChild(downloadLink)
        })
        setImageSaved((prevState) => true)
      }
    } catch (error) {
      console.error('Error capturing snapshot:', error)
    }
  }

  const handleChange = async ({ fileList: newFileList }) => {
    try {
      const updatedFileList = newFileList.map((file) => ({
        ...file,
        status: 'done',
      }))
      setFileList((prevState) => updatedFileList)
      setImageFileList((prevState) => updatedFileList)
    } catch (error) {
      console.error('Error during file upload:', error)
      const updatedFileList = newFileList.map((file) => ({
        ...file,
        status: 'error',
      }))
      setFileList((prevState) => updatedFileList)
      setImageFileList((prevState) => updatedFileList)
    }
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Add Logo</div>
    </div>
  )

  const beforeUpload = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setImageUrl(e.target.result)
    }
    reader.readAsDataURL(file)
    return false
  }

  function handleRemoveBackground() {
    setRemovingBg((prevState) => true)
    if (fileList.length === 0) {
      return
    }

    const apiKey = 'tntfiNmFJCG64vzcyfTvLqGW'
    const apiUrl = 'https://api.remove.bg/v1.0/removebg'

    const formData = new FormData()
    formData.append('image_file', fileList[0].originFileObj)

    axios
      .post(apiUrl, formData, {
        headers: {
          'X-Api-Key': apiKey,
        },
        responseType: 'blob',
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const imageUrl = URL.createObjectURL(blob)
        setImageUrl(imageUrl)
      })
      .catch((error) => {
        console.error('Error removing background:', error.message)
      })
    setRemovingBg((prevState) => false)
  }

  return (
    <div className="personalize-view">
      <div
        style={{
          width: '15%',
        }}
        className="upload-section"
      >
        <Upload
          listType="picture-circle"
          fileList={fileList}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          multiple={false}
          accept=".png,.jpg,.jpeg"
          maxCount={1}
          onRemove={() => setImageUrl(null)}
        >
          {fileList.length >= 1 ? null : uploadButton}
        </Upload>
        {fileList.length >= 1 ? (
          <Button
            loading={removingBg}
            onClick={handleRemoveBackground}
            disabled={imageSaved}
            title={imageSaved ? 'Click Edit Image to Remove Background' : ''}
            className="removingBgBackground"
          >
            {removingBg ? 'Processing...' : 'Remove Background'}
          </Button>
        ) : (
          ''
        )}
      </div>
      <div className="height-width">
        <label>Enter Width</label>
        <InputNumber
          placeholder="Logo Width"
          onChange={(value) => setLogoWidth(value)}
          value={logoWidth}
          style={{
            width: '220px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          }}
          controls={false}
        />
        <label>Enter Height</label>
        <InputNumber
          placeholder="Logo Height"
          onChange={(value) => setLogoHeight(value)}
          value={logoHeight}
          style={{
            width: '220px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          }}
          controls={false}
        />
      </div>
      <div className="image-section">
        {imageSaved ? (
          <>
            <Button
              onClick={() => {
                setModified((prevState) =>
                  prevState.filter(
                    (item) =>
                      item.printing_position !=
                      selectedPrintingPosition?.position_id
                  )
                )
                setImageSaved((prevState) => false)
              }}
              className="edit-image"
            >
              <EditOutlined />
              Edit Image
            </Button>
            <sub>Click To Edit Image</sub>
          </>
        ) : (
          <>
            <Button
              onClick={() => captureSnapshot(true)}
              className="save-image"
              title={fileList.length == 0 ? 'Please Add A Logo To Product' : ''}
              disabled={fileList.length == 0}
            >
              <SaveOutlined />
              Save Image
            </Button>
            <sub>Click To Save Image</sub>
          </>
        )}
        <div
          ref={editorRef}
          style={{
            backgroundImage: `url(${proxyImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            minHeight: '375px',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            pointerEvents: imageSaved ? 'none' : 'auto',
          }}
        >
          {imageUrl && (
            <Draggable
              handle=".draggable-handle"
              defaultPosition={{ x: 0, y: 0 }}
              bounds="parent"
              onStop={handleDragStop}
            >
              <div
                style={{
                  height: `${logoHeight}px`,
                  width: `${logoWidth}px`,
                  position: 'absolute',
                  left: 50,
                  top: 30,
                  zIndex: 10,
                  border: newPositionTop == 0 ? '3px solid #0575B4' : 'none',
                }}
                className="draggable-handle"
              >
                <img
                  alt="imageUrl Img"
                  src={imageUrl}
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.src = ''
                  }}
                />
              </div>
            </Draggable>
          )}
        </div>
      </div>
    </div>
  )
}

const Personalize = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [requestedStock, setRequestedStock] = useState(parseInt(state.stock))
  const [totalCostValue, setTotalCostValue] = useState([])
  const [selectedPrintingPosition, setSelectedPrintingPosition] = useState([])
  const [fileList, setFileList] = useState([])
  const [selectedPrintTechnique, setSelectedPrintTechnique] = useState()
  const [orderLoader, setOrderLoader] = useState(false)
  const [selectedPrintingTechniques, setSelectedPrintingTechniques] = useState(
    []
  )
  const { user } = useSelector((state) => state.user)
  const [address, setAddress] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [uploadSrc, setUploadSrc] = useState(
    process.env.PUBLIC_URL + '/assets/loaders/upload/upload.webp'
  )
  const [modified, setModified] = useState([])
  const [current, setCurrent] = useState(0)

  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  useEffect(() => {
    if (
      selectedPrintingPosition.length > 0 &&
      selectedPrintingTechniques.length > 0
    ) {
      const initialTotalCostValue = selectedPrintingPosition.map(
        (position, index) => {
          const selectedTechnique = selectedPrintingTechniques[index]
          const setupCost = getAEDPrice(
            position.printing_techniques.find(
              (technique) => technique.id === selectedTechnique.ptp
            ).setup
          )
          const printingCost = calculatePrintingCost(
            position,
            selectedTechnique,
            requestedStock
          )
          return {
            setup_cost: setupCost,
            print_position_id: position._id,
            printing_cost: printingCost,
          }
        }
      )
      setTotalCostValue(initialTotalCostValue)
    }
  }, [
    selectedPrintingPosition,
    selectedPrintingTechniques,
    requestedStock,
    selectedPrintTechnique,
  ])

  const calculatePrintingCost = (position, technique, stock) => {
    let printingCost = 0
    position.printing_techniques
      .filter((item) => item.id === selectedPrintTechnique)[0]
      ?.var_costs[0]?.scales.forEach((scale) => {
        if (
          parseInt(scale.minimum_quantity.replace('.', '')) <= parseInt(stock)
        ) {
          printingCost = getAEDPrice(scale.price) * parseInt(stock)
        }
      })
    return printingCost.toFixed(2)
  }

  function handlePrintPositionClick(item) {
    setSelectedPrintingPosition((prevState) => {
      const isItemPresent = prevState.some(
        (prevItem) => prevItem._id === item._id
      )
      if (isItemPresent) {
        return [...prevState.filter((pp) => pp.position_id != item.position_id)]
      } else {
        return [...prevState, item]
      }
    })
  }

  function getTotalProductPrice() {
    let price = parseFloat(
      (
        state?.product?.variants[state?.variantIndex].price * requestedStock
      ).toFixed(2)
    )
    totalCostValue.forEach((item) => {
      price += parseFloat(item.setup_cost) + parseFloat(item.printing_cost)
    })
    return price
  }

  const createPayload = () => {
    if (!modified) {
      let initialOrderPayload = {
        products: [],
        total: 0,
        payment_method: 'COD',
        status: 'pending',
      }

      initialOrderPayload.products.push({
        product_id: state?.product?._id,
        master_id: state?.product?.master_id,
        variant_id: state?.product?.variants[state?.variantIndex].variant_id,
        quantity: requestedStock,
        unit_price: state?.product?.variants[state?.variantIndex].price,
        total_price:
          parseFloat(state?.product?.variants[state?.variantIndex].price) *
          requestedStock,
        modified: modified,
      })
      initialOrderPayload['total'] = getTotalProductPrice().toFixed(2)
      return initialOrderPayload
    } else {
      let formData = new FormData()

      formData.append('payment_method', 'COD')
      formData.append('status', 'pending')

      formData.append(
        'products',
        JSON.stringify({
          product_id: state.product._id,
          master_id: state.product.master_id,
          variant_id: state.product.variants[state.variantIndex].variant_id,
          quantity: requestedStock,
          unit_price: state.product.variants[state.variantIndex].price,
          total_price:
            parseFloat(state.product.variants[state.variantIndex].price) *
            requestedStock,
          modified: modified,
        })
      )
      formData.append('is_modified', true)
      formData.append('total', getTotalProductPrice().toFixed(2))
      return formData
    }
  }

  const handleCheckout = async () => {
    setOrderLoader((prevState) => true)
    const result = await postCall(`/orders/add_Order`, createPayload())
    if (result?.status) {
      cogoToast.success('Order Submitted Successfully')
      dispatch(deleteAllFromCart())
    }
    setOrderLoader((prevState) => false)
    navigate('/my-orders')
  }

  const steps = [
    {
      title: 'Select Printing',
      content: (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <h4>
            Personalize Product{' '}
            <span style={{ fontWeight: 400, fontSize: 14, color: '#737373' }}>
              (Place Your Logo)
            </span>
          </h4>
          <div className="add-logo-section">
            <label>Select Printing Position</label>
            <Button
              className="addLogoBtn"
              disabled={selectedPrintingPosition.length == 0}
              title={
                selectedPrintingPosition.length == 0
                  ? 'Please Add Atleast One Printing Position'
                  : ''
              }
              onMouseOver={() =>
                setUploadSrc(
                  process.env.PUBLIC_URL + '/assets/loaders/upload/upload.gif'
                )
              }
              onMouseLeave={() =>
                setUploadSrc(
                  process.env.PUBLIC_URL + '/assets/loaders/upload/upload.webp'
                )
              }
              onClick={() => next()}
            >
              {selectedPrintingPosition.length == 0 ? null : (
                <img
                  height={25}
                  width={25}
                  src={uploadSrc}
                  alt="uploadSrc Img"
                />
              )}
              Add Logo
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '5px',
            }}
          >
            <label>Selected Positions :</label>
            <span>{selectedPrintingPosition.length}</span>
          </div>
          <div
            style={{
              display: 'flex',
              gap: '20px',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginBottom: '40px',
              marginTop: '40px',
              justifyContent: 'flex-start',
            }}
          >
            {state.product.printing_positions.map((item) => (
              <div
                key={item._id}
                className={`printing-position-card ${
                  selectedPrintingPosition.some(
                    (prevItem) => prevItem._id === item._id
                  )
                    ? 'printing-position-card-selected'
                    : ''
                }`}
                onClick={() => handlePrintPositionClick(item)}
              >
                <span>{item.position_id}</span>
                <img
                  alt="variantColorCode"
                  src={
                    item.images.find(
                      (image) => image.variant_color === state.variantColorCode
                    ).print_position_image_with_area
                  }
                  height={250}
                  width={250}
                />
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      title: 'Upload Logo',
      content: (
        <div className="upload-logo-step">
          <Button
            className="make-my-product"
            style={{
              marginLeft: 'auto',
            }}
            disabled={fileList.length == 0 || modified.length == 0}
            title={
              fileList.length == 0
                ? 'Please Upload An Image'
                : modified.length == 0
                ? 'Please Save The Print Position'
                : 'Order My Product'
            }
            onClick={handleCheckout}
            loading={orderLoader}
          >
            Order My Product
          </Button>
          {showModal && (
            <Modal
              open={showModal}
              onCancel={() => setShowModal((prevState) => false)}
              footer={null}
            >
              <h4>Checkout</h4>
              <label
                style={{
                  color: '#333',
                  fontWeight: 600,
                  marginBottom: '10px',
                }}
              >
                Select Address
              </label>
              <div
                style={{
                  border: '1px solid #737373',
                  boxSizing: 'border-box',
                  padding: '10px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                <Dropdown
                  overlay={
                    <Menu>
                      {user.address.map((item) => (
                        <Menu.Item
                          onClick={() => setAddress((prevState) => item)}
                        >
                          {item?.address1}, {item?.address2}, {item?.address3},
                          {item.city},{item.state}
                        </Menu.Item>
                      ))}
                      <Menu.Item>
                        <a href="/my-account">
                          <PlusOutlined /> Add Address
                        </a>
                      </Menu.Item>
                    </Menu>
                  }
                  placement="Select Address"
                >
                  {address ? (
                    <span>
                      {address?.address1}, {address?.address2},{' '}
                      {address?.address3}, {address?.city}, {address?.state}
                    </span>
                  ) : (
                    <span
                      style={{
                        display: 'flex',
                        width: '100%',
                      }}
                    >
                      Select Address
                    </span>
                  )}
                </Dropdown>
              </div>
              <label
                style={{
                  color: '#333',
                  fontWeight: 600,
                  marginBottom: '10px',
                  marginTop: '10px',
                }}
              >
                Payment Reference Number
              </label>
              <Input placeholder="Enter Your Payment Reference Number" />
              <div className="checkout-area">
                <Button
                  className="complete-checkout-btn"
                  onClick={handleCheckout}
                >
                  Complete Checkout
                </Button>
              </div>
            </Modal>
          )}
          {selectedPrintingPosition.length > 0 &&
            selectedPrintingPosition.map((item, index) => (
              <div key={item._id} style={{ display: 'flex' }}>
                <ImageEditorComponent
                  selectedPrintingPosition={item}
                  variantColorCode={state.variantColorCode}
                  setImageFileList={setFileList}
                  selectedTechnique={selectedPrintingTechniques[index]}
                  requestedStock={requestedStock}
                  totalCostValue={totalCostValue}
                  setTotalCostValue={setTotalCostValue}
                  index={index}
                  setModified={setModified}
                  modified={modified}
                  selectedPrintTechnique={selectedPrintTechnique}
                  setSelectedPrintTechnique={setSelectedPrintTechnique}
                />
              </div>
            ))}
        </div>
      ),
    },
  ]

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }))

  return (
    <>
      <HeaderOne />
      <div
        style={{
          width: '90%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxSizing: 'border-box',
          padding: '10px',
        }}
      >
        <div
          style={{
            width: '30%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            gap: '5px',
          }}
        >
          <label className="head-label">Stock Value</label>
          <InputNumber
            placeholder="Enter Stock"
            style={{
              width: 200,
              height: 40,
              display: 'flex',
              alignItems: 'center',
            }}
            controls={false}
            value={requestedStock}
            type="number"
            onChange={(e) => {
              if (e < 0) {
                setRequestedStock((prevState) => 1)
              } else {
                if (parseInt(e) < parseInt(state.totalStock)) {
                  setRequestedStock((prevState) => parseInt(e))
                } else {
                  setRequestedStock((prevState) => parseInt(state.totalStock))
                  cogoToast.warn('Maximum Stock Count Reached', {
                    position: 'top-center',
                  })
                }
              }
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            alignItems: 'center',
          }}
        >
          <label className="head-label">Total Cost</label>
          <span>AED {getTotalProductPrice().toFixed(2)}</span>
        </div>
      </div>
      <div className="steps-to-order">
        <Collapse
          defaultActiveKey={['1']}
          items={[
            {
              key: '1',
              label: 'Steps To Order Personalized Product',
              children: (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                  }}
                >
                  <span>1. Select Printing Position</span>
                  <span>2. Click Add Logo Button</span>
                  <span>
                    3. Drag and Drop the Image Inside the Dotted Section ,
                    Please make sure to place the image inside the dotted
                    section
                  </span>
                  <span>
                    4. Adjust the height and width of the logo if needed
                  </span>
                  <span>5. Save the Image to Fix the Position</span>
                  <span>6. Click Order My Product</span>
                </div>
              ),
            },
          ]}
        />
      </div>
      <div
        style={{
          width: '90%',
          margin: '0 auto',
          marginTop: '1%',
          minHeight: '75vh',
        }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
              marginBottom: '20px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}
        <Steps current={current} items={items} />
        <div style={{ marginTop: '20px' }}>{steps[current].content}</div>
      </div>
      <FooterOne />
    </>
  )
}

export default Personalize
