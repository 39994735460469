import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { Button, Input, notification } from 'antd'
import { useState } from 'react'
import { emailRegex } from '../../components/main-landing-page/utils/regex'
import '../../components/main-landing-page/components/footer/_footer.scss'

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const [facebookSrc, setFacebookSrc] = useState(
    process.env.PUBLIC_URL + '/assets/social-icons/facebook.webp'
  )

  const [youtubeSrc, setYoutubeSrc] = useState(
    process.env.PUBLIC_URL + '/assets/social-icons/youtube.webp'
  )
  const [linkedinSrc, setlinkedinSrc] = useState(
    process.env.PUBLIC_URL + '/assets/social-icons/linkedin.webp'
  )
  const [instagramSrc, setInstagramSrc] = useState(
    process.env.PUBLIC_URL + '/assets/social-icons/instagram.webp'
  )
  const [email, setEmail] = useState()

  function handleSubscription() {
    notification.success({
      message: 'Subscribed Successfully',
      placement: 'bottomRight',
    })
  }

  return (
    <>
      <div className="newsletter-main">
        <div className="newsletter-main-inner-sec">
          <div className="newsletter-genie-arabia-l">
            <h3 style={{ lineHeight: '45px' }}>Love gifts? Like offer?</h3>
            <span>
              Indulge in the art of affection with our exquisite Love Gift
              Offer. Elevate your expressions of love with a carefully curated
              selection of heartfelt treasures, designed to captivate the senses
              and deepen your connection.
            </span>
          </div>
          <div className="newsletter-genie-arabia-r">
            <Input
              placeholder="Your Email Address"
              onChange={(e) => setEmail(e.target.value)}
              addonAfter={
                <Button
                  className="subscribebtn"
                  onClick={handleSubscription}
                  disabled={!emailRegex.test(email)}
                >
                  Subscribe
                </Button>
              }
            />
          </div>
        </div>
      </div>
      <footer
        className={clsx(
          'footer-area',
          backgroundColorClass,
          extraFooterClass,
          spaceLeftClass,
          spaceRightClass
        )}
      >
        <div
          style={{
            boxSizing: 'border-box',
            paddingTop: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <img
              alt="logo Img"
              src={process.env.PUBLIC_URL + '/assets/img/logo/logo_11zon.webp'}
              style={{width:"195px",height:"100%",objectFit:"contain"}}
            />
            <div className="social-link-genie-arabia">
              <div className="footer-list">
                <ul>
                  <li>
                    <Link
                      to={'https://www.facebook.com/profile.php?id=61558258028107'}
                      target="_blank"
                      className="social-link"
                      onMouseOver={() => {
                        setFacebookSrc(
                          process.env.PUBLIC_URL +
                            '/assets/social-icons/edited/facebook.webp'
                        )
                      }}
                      onMouseLeave={() => {
                        setFacebookSrc(
                          process.env.PUBLIC_URL +
                            '/assets/social-icons/facebook.webp'
                        )
                      }}
                    >
                      <img
                        src={facebookSrc}
                        alt="facebookSrc Img"
                        height={40}
                        width={40}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'https://www.instagram.com/genie_arabia/'}
                      target="_blank"
                      className="social-link"
                      onMouseOver={() => {
                        setInstagramSrc(
                          process.env.PUBLIC_URL +
                            '/assets/social-icons/edited/instagram.webp'
                        )
                      }}
                      onMouseLeave={() => {
                        setInstagramSrc(
                          process.env.PUBLIC_URL +
                            '/assets/social-icons/instagram.webp'
                        )
                      }}
                    >
                      <img
                        src={instagramSrc}
                        alt="instagram Img"
                        height={40}
                        width={40}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'https://www.youtube.com/@genie_arabia'}
                      target="_blank"
                      className="social-link"
                      onMouseOver={() => {
                        setYoutubeSrc(
                          process.env.PUBLIC_URL +
                            '/assets/social-icons/edited/youtube.webp'
                        )
                      }}
                      onMouseLeave={() => {
                        setYoutubeSrc(
                          process.env.PUBLIC_URL +
                            '/assets/social-icons/youtube.webp'
                        )
                      }}
                    >
                      <img
                        src={youtubeSrc}
                        alt="Youtube Img"
                        height={40}
                        width={40}
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={'https://www.linkedin.com/company/geniearabia-com/'}
                      target="_blank"
                      className="social-link"
                      onMouseOver={() => {
                        setlinkedinSrc(
                          process.env.PUBLIC_URL +
                            '/assets/social-icons/edited/linkedin.webp'
                        )
                      }}
                      onMouseLeave={() => {
                        setlinkedinSrc(
                          process.env.PUBLIC_URL +
                            '/assets/social-icons/linkedin.webp'
                        )
                      }}
                    >
                      <img
                        src={linkedinSrc}
                        alt="linkedin Img"
                        height={40}
                        width={40}
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-list">
              <ul>
                <li style={{ marginRight: '5px' }}>
                  <Link to={process.env.PUBLIC_URL + '/shop'}>Shop</Link>
                </li>
                <li style={{ marginRight: '5px' }}>
                  <Link
                    to="https://online.flippingbook.com/view/751447315/"
                    target="_blank"
                  >
                    Catalogue
                  </Link>
                </li>
                <li style={{ marginRight: '5px' }}>
                  <Link
                    to="/printingTechnique"
                    className="list-item"
                    target="_blank"
                  >
                    Printing Techniques
                  </Link>
                </li>
                <li style={{ marginRight: '5px' }}>
                  <Link to={process.env.PUBLIC_URL + '/terms-and-conditions'}>
                    Terms And Conditions
                  </Link>
                </li>
                <li style={{ marginRight: '5px' }}>
                  <Link to={process.env.PUBLIC_URL + '/cancellation-policy'}>
                    Cancellation Policy
                  </Link>
                </li>
                <li style={{ marginRight: '5px' }}>
                  <Link to={process.env.PUBLIC_URL + '/delivery-policy'}>
                    Delivery Policy
                  </Link>
                </li>
                <li style={{ marginRight: '5px' }}>
                  <Link to={process.env.PUBLIC_URL + '/return-policy'}>
                    Return Policy
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + '/privacy-policy'}>
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div
              style={{
                borderTop: '1px solid #000',
                width: '100%',
                boxSizing: 'border-box',
                marginTop: '40px',
                padding: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
              className="copyright-footer"
            >
              <span>
                &copy; 2023 - {new Date().getFullYear()}{' '}
                <a
                  href={process.env.PUBLIC_URL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Genie Arabia
                </a>
                . All Rights Reserved
              </span>
              <a href="https://dailydaysolutions.com/">Powered By Daily Day Solutions</a>
              <span>
              <img
                  alt="logo Img"
                  src={process.env.PUBLIC_URL + '/assets/img/logo/visa.webp'}
                  style={{width:"135px",height:"80%",objectFit:"contain"}}
                />
                              <img
                  alt="logo Img"
                  src={process.env.PUBLIC_URL + '/assets/img/logo/master.webp'}
                  style={{width:"135px",height:"80%",objectFit:"contain"}}
                />
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
}

export default FooterOne
