const CopperBottles = [
  {
    master_id: "CP-1",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 01",
    price: "USD 8.75",
    product_name: "Plain Bottle",
    short_description:
      "This is a Plain Copper Bottle, Suitable for everyday use.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Plain Copper Bottle CPB - 01.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Plain Copper Bottle CPB - 01 (second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-2",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 02",
    price: "USD 8.43",
    product_name: "Dr. Bottle",
    short_description: "This is a Dr. Bottle, has an elegant design .",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Dr. Copper Bottle CPB - 02.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-3",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 03",
    price: "USD 8.75",
    product_name: "Milton Bottle",
    short_description:
      "This is a Milton Copper Bottle, similar to Plain Copper Bottle, but has a differene of Bottle cap design.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Milton Copper Bottle CPB - 03.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Milton Copper Bottle CPB - 03 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-4",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 04",
    price: "USD 8.00",
    product_name: "Gola Bottle",
    short_description:
      "This is Gola Bottle, made of pure copper, has a circular design and a premum quality.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Gola Bottle CPB - 04.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-5",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 05",
    price: "USD 9.75",
    product_name: "Diamond Bottle",
    short_description:
      "This is Diamond Copper Bottle, made of pure Copper, the design is similar to diamond design and hence the name Diamond Bottle.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Diamond Bottle CPB - 05.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Dimond Bottle CPB - 05 (Second image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Diamond Bottle CPB - 05 (Third Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-6",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 06",
    price: "USD 9.75",
    product_name: "Hammered Bottle",
    short_description:
      "This is Hammered Copper Bottle.  The desing on this bottle is hammered which gives this a very elegant look.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Hammered Bottle CPB - 06.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Hammered Bottle CPB - 06 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-7",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 07",
    price: "USD 9.75",
    product_name: "Sipper Bottle",
    short_description:
      "This a sipper Bottle, made of pure copper this bottle is fitted with a plastic cap and a sipper pipe. Which makes it perfect for use in Gyms, Sports and easy to carry. (One of the most selling products.)",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Sipper Bottle CPB - 07.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Sipper Bottle CPB - 07 (Second Image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Sipper Bottle CPB - 07 (Third Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-8",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 08",
    price: "USD 9.75",
    product_name: "Ruby Bottle",
    short_description:
      "This is Ruby Bottle, a very premium Bottle and a heavy built Quality. (This bottles are highly in Demand)",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Ruby Bottle CPB - 08.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Ruby Bottle CPB - 08 (Second Bottle).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-9",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 09",
    price: "USD 9.75",
    product_name: "Plain & Hammered Bottle",
    short_description:
      "This Is Plain & Hammered Bottle, This bottle is a mix of PLAIN bottle and Hammered Bottle",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Plain & Hammered CPB - 09.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Plain & Hammered CPB - 09 (Second Image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Plain & Hammered CPB - 09 (Third Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-11",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 11",
    price: "USD 9.75",
    product_name: "Printed Ruby Bottle",
    short_description:
      "This is Printed Ruby Bottle, this bottle is same to ruby, DESIGNS can be printed on this bottles.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Printed Ruby  CPB - 11.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Printed Ruby  CPB - 11 (Second image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Printed Ruby  CPB - 11 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-12",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 12",
    price: "USD 9.75",
    product_name: "Black & Hammered Bottle",
    short_description:
      "This is very similar to Hammered Bottle. It is drak in color which gives it a vintage look.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Black & Hammered CPB - 12.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Black & Hammered CPB - 12 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-13",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 13",
    price: "USD 9.75",
    product_name: "Black & Diamond Bottle",
    short_description:
      "This is very similar to Diamond Bottle, This bottle is dark in color which gives it a antique look.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Black Diamond CPB - 13.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Black Diamond CPB - 13 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-14",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 14",
    price: "USD 9.75",
    product_name: "Curve & printed Bottle",
    short_description:
      "This bottle has a curved design, any design can be printed on it as per the requirement.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Curve & printed CPB - 14.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Curve & printed CPB - 14 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-15",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 15",
    price: "USD 9.75",
    product_name: "Plain Printed Bottle",
    short_description:
      "This bottle is exactly same as the plain bottle, Any design can be printed on it.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Plain Printed CPB - 15.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Plain Printed CPB - 15 ( Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-16",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 16",
    price: "USD 9.75",
    product_name: "Engraved Bottle",
    short_description:
      "This bottles has a very appealing design and made of high quality copper.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Engraved CPB - 16.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Engraved CPB - 16 ( Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-17",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 17",
    price: "USD 9.75",
    product_name: "Wooden Bottle",
    short_description:
      "This is BHADANI EXIMS Wooden bottle, this bottle is made up of wood on this outside and copper on the insde. This makes it a unique bottle.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Wooden Copper Bottle CPB - 17.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Wooden Copper Bottle CPB - 17 ( Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-18",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 18",
    price: "USD 9.75",
    product_name: "Tower Bottle",
    short_description:
      "This tower bottle is available in both plain and printed designs.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Tower Bottle CPB - 18.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Tower Bottle CPB - 18 (Second Image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Tower Bottle CPB - 18 (Third Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-19",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 19",
    price: "USD 9.75",
    product_name: "China Print Bottle",
    short_description: "This China Print Bottle, has an amazing design on it .",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/China Print Bottle CPB - 19.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-20",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 20",
    price: "USD 9.75",
    product_name: "China Plain Bottle",
    short_description:
      "This is exactly same to CHINA PRINT, but this bottle is plain.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/China Bottle Plain CPB - 20.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-21",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 21",
    price: "USD 9.75",
    product_name: "Sipper Black Bottle",
    short_description: "This Sipper Bottle has hammered design on it.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Sipper Black CPB - 21.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Sipper Black CPB - 21 ( second Image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Sipper Black CPB - 21 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-22",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 22 - A",
    price: "USD 9.75",
    product_name: "Sipper Meena Bottle",
    short_description:
      "This is meena sipper Bottle. Designs can be printed on it.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Sipper Meena CPB - 22.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Sipper Meena CPB - 22 (second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-23",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 22",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 22 is a copper bottle gift set, this comes with one Engraved copper Bottle and 2 copper glass of same design. PERFECT OPTION FOR GIFTING",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/copper bottles/Engraved CPB - 22.jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Engraved CPB - 22 (second image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/Engraved CPB - 22 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-24",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 23",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 23 is a copper bottle gift set, this comes with one Plain Printed  copper Bottle and 2 copper glass of same design. PERFECT OPTION FOR GIFTING",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPB - 23.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPB - 23 (Second image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/CPB - 23 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-25",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 24",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 24 is a copper bottle gift set, this comes with one Crushed Hammered Copper Bottle and 2 copper glass of same design.  This looks very elegant, PERFECT OPTION FOR GIFTING",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPB - 24.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPB - 24 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-26",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 25",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 25is a copper bottle gift set, this comes with one Engraved copper Bottle in golden color and 2 copper glass of same design.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPB - 25.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPB - 25 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-27",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 26",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 26 is a copper bottle gift set, this comes with one Plain copper Bottle and 2 copper glass of same design.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPB - 26.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPB - 26 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-28",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 27",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 27 is a copper bottle gift set, this comes with Dr. Bottle in hammered design copper Bottle and 2 copper glass of same design. PERFECT OPTION FOR GIFTING",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPB - 27.jpg" },
        ],
      },
    ],
  },
  {
    master_id: "CP-29",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 28",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 28 is a copper bottle gift set, this comes with one Palin hammered copper Bottle and 2 copper glass of same design.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPB - 28.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPB - 28 (Second Image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/CPB - 28 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-30",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 29",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 29 is a copper bottle gift set, this comes with one Half Hammered copper Bottle and 2 copper glass of same design. PERFECT OPTION FOR GIFTING",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPB - 29.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPB - 20 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-31",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPB - 30",
    price: "USD 17.99",
    product_name: "COPPER BOTTLES SETS",
    short_description:
      "CPB 30 is a copper bottle gift set, this comes with one Engraved copper Bottle and 2 copper glass of same design. PERFECT OPTION FOR GIFTING",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPB - 30.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPB - 30 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-32",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 01",
    price: "USD 5.00",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This is Plain Copper Mug and has a round design, We can customize the design, we can add a layer of nickle or steel on the inside if required. These can be made in various sizes like 250ml, 475ml, 950ml.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 01.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 01 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-33",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 02",
    price: "USD 5.00",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This is Plain Copper Mug, We can customize the design, we can add a layer of nickle or steel on the inside if reqired. These can be made in various sizes like 250ml, 475ml, 950ml.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 02.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 02 (Second Image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/CPM - 02 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-34",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 03",
    price: "USD 5.00",
    product_name: "COPPER MUGS/CUPS",
    short_description: "This is polished coppere mug , comes in various sizes",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 03.jpg" },
        ],
      },
    ],
  },
  {
    master_id: "CP-35",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 04",
    price: "USD 5.23",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This polished hammmered mug  with elegant pattern is exculsively designed for serving drinks etc.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 04.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 04 (Second image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/CPM - 04 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-36",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 05",
    price: "USD 5.23",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This copper mug with brass handle is testament to elegance. Can be custumized in various sizes.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 05.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 05 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-38",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 09",
    price: "USD 5.99",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This unique customizable copper barrel mug resembles a whiskey barrel perfect for your Moscow mule or favourite cocktails. This mug will keep your drink cold for hours enhancing the taste.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 09.jpg" },
        ],
      },
    ],
  },
  {
    master_id: "CP-39",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 10",
    price: "USD 5.99",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This copper mug with diamond design is a drinking vessel made of high quality copper.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 10.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 10 (Second image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/CPM - 10 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-40",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 11",
    price: "USD 5.99",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This copper printed mug is customizable made of high quality copper.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 11.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 11 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-41",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 12",
    price: "USD 5.99",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This is an exclusive copper mug with rare design and pattern comes in various sizes and keep the drinks cold for longer duration.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 12.jpg" },
        ],
      },
    ],
  },
  {
    master_id: "CP-42",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 13",
    price: "USD 6.00",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This black hammered copper mug gives a classy and antique look suitable for serving and gifting.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 13.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 13 (Second image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/CPM - 13 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-43",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 14",
    price: "USD 6.00",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This golden coloured embedded copper mug resembles a true ancient Indian arts and is a testament to royality.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 14.jpg" },
        ],
      },
    ],
  },
  {
    master_id: "CP-44",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 15",
    price: "USD 5.24",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This hammered copper mug is palin and simple suitable for serving drinks. The size can be customised as per the requirement.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 15.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 15 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-45",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPM - 16",
    price: "USD 5.99",
    product_name: "COPPER MUGS/CUPS",
    short_description:
      "This antique hammered copper mug is a blend of various colours which gives it a unique looK and texture.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPM - 16.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPM - 16 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-46",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPG - 07",
    price: "USD 5.99",
    product_name: "COPPER GLASS",
    short_description:
      "This plain copper Glass is made of high quality copper, has capacity od 300ml (customizable), can be paired with plain copper Bottle",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPG - 07.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPG - 07 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-47",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPG - 08",
    price: "USD 5.99",
    product_name: "COPPER GLASS",
    short_description:
      "This plain lined copper glass has a lined texture on the out side.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPG - 08.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPG - 08 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-48",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPG - 09",
    price: "USD 5.99",
    product_name: "COPPER GLASS",
    short_description:
      "This beautiful hammered copper glass, is made of high quality copper, suitable for serving drinks and keeps them cold for longer durations.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPG - 09.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPG - 09 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-49",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPG - 10",
    price: "USD 5.99",
    product_name: "COPPER GLASS",
    short_description:
      "This elegant Diamond design copper glass, is best paired with diamond bottle, makes it a perfect option for individual use or gifting.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPG - 10.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPG - 10 (Second image).jpg",
          },
          {
            url: "/assets/other-products/copper bottles/CPG - 10 (Third image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-50",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPG - 11",
    price: "USD 5.99",
    product_name: "COPPER GLASS",
    short_description:
      "This plain printed copper glass has a customizable print on it and any design can be printed on it as per the requirement.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPG - 11.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPG - 11 (Second Image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-51",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPG - 12",
    price: "USD 5.99",
    product_name: "COPPER GLASS",
    short_description:
      "This half hammered copper glass is testament to elegance made of high quality copper.",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPG - 12.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPG - 12 (Second image).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "CP-52",
    category: "copper_bottles",
    search_category: "copper_bottles",
    category_link: "Copper Bottles",
    master_code: "CPG - 13",
    price: "USD 5.99",
    product_name: "COPPER GLASS",
    short_description:
      "This engraved copper glass is best paired with engaved copper bottles",
    material: "Copper",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/copper bottles/CPG - 13.jpg" },
          {
            url: "/assets/other-products/copper bottles/CPG - 13 (Second image).jpg",
          },
        ],
      },
    ],
  },
];

// price converter
CopperBottles.forEach((bottle) => {
  // Extracting the price and removing "USD"
  const priceParts = bottle.price.split(" ");
  const priceUSD = parseFloat(priceParts[1]);
  // Multiplying the price by 3.67
  const newPrice = (priceUSD * 3.67).toFixed(2);
  // Updating the price without currency
  bottle.price = newPrice;
});

export default CopperBottles;
