import { Fragment } from 'react'
import HeaderOne from '../wrappers/header/HeaderOne'
import FooterOne from '../wrappers/footer/FooterOne'
import { Link } from 'react-router-dom'
import SectionTitleTwo from '../components/section-title/SectionTitleTwo'

export default function MadeInIndiaProducts() {

    let categoryGrid = [
       {
        key: 'Made-In-India-Products',
        value : "Premium Leather Gifts",
        search_category: 'leather_products',
        imgSrc: process.env.PUBLIC_URL + '/assets/category/MadeInIndia/leather-gifts.jpg',
      },
      {
        key: 'Made-In-India-Products',
        value : "Classical Gifts",
        search_category: 'classical_gifts',
        imgSrc: process.env.PUBLIC_URL + '/assets/category/MadeInIndia/classical-gifts.jpg',
      },
      {
        key: 'Made-In-India-Products',
        value : "Wooden Desk Accessories",
        search_category: 'wooden_desk',
        imgSrc: process.env.PUBLIC_URL + '/assets/category/MadeInIndia/wooden-desk.jpg',
      },
      {
        key: 'Made-In-India-Products',
        value : "Journals & Games ",
        search_category: 'games',
        imgSrc: process.env.PUBLIC_URL + '/assets/category/MadeInIndia/journals-games.jpg',
      },
      {
        key: 'Made-In-India-Products',
        value : "Pens & Diary",
        search_category: 'pens_and_diary',
        imgSrc: process.env.PUBLIC_URL + '/assets/category/MadeInIndia/pens-diary.jpg',
      },
      {
        key: 'Made-In-India-Products',
        value : "Copper Bottles",
        search_category: 'copper_bottles',
        imgSrc: process.env.PUBLIC_URL + '/assets/category/MadeInIndia/copper-bottles.jpg',
      }
    ]

  return (
    <div>
        <HeaderOne></HeaderOne>
        <SectionTitleTwo
          titleText="Made In India Products"
          subTitleText="Giftable, charming, exclusive, delightful, curated."
          positionClass="text-center"
          spaceClass="mt-40 mb-40"
        />
        <div
          className="row"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
        <Fragment>
            <div
                style={{
                    display: 'inline-grid',
                    gridTemplateColumns: 'repeat(auto-fill , 400px)',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    justifyContent: 'center',
                }}
                >
                {categoryGrid
                    ?.map((category) => {
                    return (
                        <Link
                        to={`/${category.key}`}
                        state={category.search_category}
                        className="category-card"
                        key={category?.key}
                        >
                        <img src={category.imgSrc} alt={category.value} />
                        <span className="category-text">{category.value}</span>
                        </Link>
                    )
                    })}
            </div>
        </Fragment>
        </div>
        <FooterOne></FooterOne>
    </div>
  )
}
