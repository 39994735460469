import React from "react";
import "./terms-and-conditions.scss";
import HeaderOne from "../../wrappers/header/HeaderOne";
import FooterOne from "../../wrappers/footer/FooterOne";
import SEO from "../seo";

const TermsAndConditions = () => {
  return (
    <>
      <HeaderOne />
      <SEO
        titleTemplate="Terms and conditions"
        description="Terms and conditions"
      />
      <div className="terms-and-condition-genie-arabia">
        <div className="static-content-area">
          <h1>Introduction</h1>
          <ul>
            <li>
              By accessing and using this website, operated by G CUBE
              INTERNATIONAL FZCO, you accept and abide by these terms and
              conditions; Consequently, if you disagree with any part of these
              terms and conditions, you are not permitted to use our website.
            </li>
            <li>
              If you choose to register with our website, we will specifically
              ask you to comply with our terms and conditions.
            </li>
            <li>
              To use our website, you must be at least 18 years of age. By
              accessing our website or consenting to these terms and conditions,
              you affirm and warrant to us that you meet the minimum requirement
              age.
            </li>
            <li>
              Our website employs cookies; by using our website or accepting
              these terms and conditions, you express your consent to our
              utilization of cookies, as per the specifications outlined in our
              website.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Copyrights</h6>
          <ul>
            <li>
              You are allowed to: <br />
              (a) view, and download from our website in a web browser. <br />
              (b) stream audio and video files from our website and <br />
              (c) use our website services through a web browser, all of which
              are subject to the other provisions of these terms and conditions.
            </li>
            <li>
              Unless explicitly permitted BY THE terms and conditions, you are
              prohibited from downloading or saving any material from our
              website to your computer.
            </li>
            <li>
              You are restricted to utilizing our website solely for your
              personal and business purposes, and engaging in any other purposes
              is strictly forbidden.
            </li>
            <li>
              Editing or modifying any material on our website is not allowed
              except as specifically permitted by these terms and conditions.
            </li>
            <li>
              Unless you own or control the relevant rights in the material, you
              must not: <br /> (a) Reuse any content from our website (including
              republication on another website). <br />
              (b) Engage in the sale, rental, or sub-licensing of any material
              from our website. <br />
              (c) Display and redistribute any material from our website in
              public. <br />
              (d) Utilize any material from our website for commercial purposes.
            </li>
            <li>
              We retain the authority to limit access to specific sections of
              our website or the entire site, at our discretion. You are not
              allowed to evade or attempt to evade any access restrictions
              imposed on our website.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Registration and Accounts</h6>
          <ul>
            <li>
              If you register for an account with our website, a password will
              be sent to your registered e-mail ID or One Time Password (OTP)
              will be generated to your registered Mobile Number to log in to
              your account.
            </li>
            <li>
              You must not use your e-mail or contact number for or in
              connection with the impersonation of any person.
            </li>
            <li>
              You must keep your password and OTP confidential and become aware
              of any disclosure of your password.
            </li>
            <li>
              You are responsible for any activity on our website arising out of
              any failure to keep your password confidential and may be held
              liable for any losses arising out of such a failure.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Login Credentials</h6>
          <ul>
            <li>
              You may register for an account with our website by completing and
              submitting the account registration with your e-mail ID and
              contact number.
            </li>
            <li>
              You must not allow any other person to use your account to access
              the website and notify us immediately in writing if you become
              aware of any unauthorized activity.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Community Forums and User Contributions</h6>
          <ul>
            <li>
              G CUBE INTERNATIONAL FZCO disclaims responsibility for any content
              you submit to public areas, including bulletin boards, user
              feedback, comments, or any other accessible sections on the
              website. Neither your submissions nor those of other users are
              endorsed, reviewed, or approved by geniearabia.com. The company
              retains the right, at its sole discretion, to remove any material
              you submit to public areas without prior notice if it determines
              that you are, or there is a likelihood that you may
              <br />
              (a) Harm, mistreat, intimidate, pursue, threaten, defame, or
              engage in any actions that infringe upon the rights of other users
              or any third parties.
              <br />
              (b) Share, upload, distribute, or circulate any material or
              information that is defamatory, obscene, indecent, or unlawful.
              <br />
              (c) Upload or post files that include viruses, corrupted files, or
              any other software or programs with the potential to disrupt the
              functioning of geniearabia.com, a third party's computer system,
              and/or network.
              <br />
              (d) Infringe upon copyrights, trademarks, or any relevant Indian
              or international laws, as well as intellectual property rights of
              geniearabia.com or any third party.
              <br />
              (e) Additionally, refrain from submitting content that includes
              marketing or promotional material intending to solicit business.
              <br />
            </li>
            <li>
              You must not allow any other person to use your account to access
              the website and notify us immediately in writing if you become
              aware of any unauthorized activity.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Warranties</h6>
          <ul>
            <li>
              We do not warrant or represent:
              <br />
              [a]. The accuracy or entirety of the information presented on our
              website. <br />
              [b]. that the content on the website is up to date; or <br />
              [c]. that the website or any services on the website will stay
              accessible.
            </li>
            <li>
              We retain the right to cease or modify any or all of our website
              services and to halt the publication of our website, at our sole
              discretion, without prior notice or explanation. Except as
              expressly stated otherwise in these terms and conditions, you will
              not be entitled to any compensation or payment upon the
              discontinuation or alteration of any website services or if we
              decide to cease publishing the website.
            </li>
            <li>
              To the maximum extent allowed by applicable law, we disclaim all
              representations and warranties concerning the subject matter of
              these terms and conditions, our website, and the utilization of
              our website.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Variation</h6>
          <ul>
            <li>
              G CUBE INTERNATIONAL FZCO reserves the right to alter any
              pertinent terms and conditions, policies, or notices at any given
              time. You acknowledge that visiting the website periodically binds
              you to the prevailing version of the relevant terms and conditions
              (the "current version"). Unless otherwise specified in the current
              version, all prior versions are replaced by the current version.
              It is your responsibility to review the current version each time
              you visit the website.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Third party rights</h6>
          <ul>
            <li>
              A contract under these terms and conditions is for our benefit and
              your benefit and is not intended to benefit or be enforceable by
              any third party.
            </li>
            <li>
              The exercise of the parties' rights under a contract under these
              terms and conditions is not subject to the consent of any third
              party.
            </li>
            <li>
              The terms and conditions together with our website, shall
              constitute the entire agreement between you and us concerning your
              use of our website and shall supersede all previous agreements
              between you and us regarding your use of our website.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6> Laws & Jurisdiction</h6>
          <ul>
            <li>
              These terms and conditions shall be governed by and construed as
              per the Indian Law.
            </li>
            <li>
              Any disputes relating to these terms and conditions shall be
              subject to the exclusive OR non-exclusive jurisdiction of the
              courts of India.
            </li>
            <li>
              If any unlawful and/or unenforceable provision of these terms and
              conditions would be lawful or enforceable if part of it were
              deleted, that part will be deemed to be deleted, and the rest of
              the provision will continue in effect.
            </li>
          </ul>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default TermsAndConditions;
