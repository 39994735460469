
import cogoToast from 'cogo-toast';
import { getCall } from '../../api/apiService';
import { userLoggedIn } from '../../helpers/user';
const { createSlice } = require('@reduxjs/toolkit');

export async function fetchCartItems(dispatch) {
    if(userLoggedIn()){
        try {
            const response = await getCall("/cart/list_cart");
            if (!!response.data) {
                dispatch(setCartItems(response.data));
            } else {
                dispatch(setCartItems([]));
            }
        } catch (error) {
            console.error("Error fetching cart items", error);
            dispatch(setCartItems([]));
        }
    }
}

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: []
    },
    reducers: {
        addToCart(state, action) {
            state.cartItems = action.payload
            cogoToast.success("Added To Cart", {position: "bottom-left"});
        },
        deleteFromCart(state, action) {
            state.cartItems = state.cartItems.filter(item => item.variant_id !== action.payload);
            cogoToast.error("Removed From Cart", {position: "bottom-left"});
        },
        decreaseQuantity(state, action){
            const product = action.payload;
            if (product.quantity === 1) {
                state.cartItems = state.cartItems.filter(item => item.variant_id !== product.variant_id);
                cogoToast.error("Removed From Cart", {position: "bottom-left"});
            } else {
                state.cartItems = state.cartItems.map(item =>
                    item.variant_id === product.variant_id
                        ? { ...item, quantity: item.quantity - 1 }
                        : item
                );
                cogoToast.warn("Item Decremented From Cart", {position: "bottom-left"});
            }
        },
        updateQuantity(state , action){
            const product = action.payload;
            if(product.quantity === 1){
                state.cartItems = state.cartItems.filter(item => item.variant_id !== product.cartItem.variant_id);
            }
            else {
                state.cartItems = state.cartItems.map(item =>
                    item.variant_id === product.cartItem.variant_id
                        ? { ...item, quantity: parseInt(action.payload.quantity)}
                        : item
                );
                cogoToast.warn("Cart Value Changed", {position: "bottom-left"});
            }
        },
        increaseQuantity(state, action){
            const product = action.payload;
            if (product.quantity === 1) {
                state.cartItems = state.cartItems.filter(item => item.variant_id !== product.variant_id);
            } else {
                state.cartItems = state.cartItems.map(item =>
                    item.variant_id === product.variant_id
                        ? { ...item, quantity: parseInt(item.quantity) + 1 }
                        : item
                );
                cogoToast.warn("Item Incremented From Cart", {position: "bottom-left"});
            }
        },
        deleteAllFromCart(state){
            state.cartItems = []
        },
        setCartItems(state, action) {
            state.cartItems = action.payload;
        },
    },
});

export const { addToCart, deleteFromCart, decreaseQuantity, deleteAllFromCart , increaseQuantity , setCartItems , updateQuantity} = cartSlice.actions;
export default cartSlice.reducer;
