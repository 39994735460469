const LeatherProducts = [
  {
    master_id: "LP-01",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-01",
    price: 450,
    product_name: "Tea Coasters",
    short_description: "Tea Coasters ( 4 Pcs)",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Logo Embossed Tea Coaster.jpg",
          },
        ],
      },
    ],
  },
  /*   {
    master_id: "LP-02",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-02",
    price: 4800,
    product_name: "Men's Black Leather Jacket",
    short_description: "Men's Black  Jackets",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Men's Black  Jackets-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Men's Black  Jackets-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Men's Black Jacket-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-03",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-03",
    price: 4800,
    product_name: "Men's Brown Leather Jacket",
    short_description: "Men's Brown  Jacket",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Men's Brown  Jacket-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Men's Brown  Jacket-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Men's Brown  Jacket-3.jpg",
          },
          {
            url: "/assets/other-products/leather products/Men's Brown  Jacket-.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-04",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-04",
    price: 4800,
    product_name: "Women's Leather Jacket",
    short_description: "Women's Red Leather Jacket",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Women's Red Leather Jacket.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-05",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-05",
    price: 3850,
    product_name: "Trolly Bag",
    short_description: "Tan Trolly Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Tan Trolly Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Tan Trolly Bag-2.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-06",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-06",
    price: 2200,
    product_name: "Duffle Bag",
    short_description: "Vintage Brown Duffle Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Vintage Brown Duffle Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Vintage Brown Duffle Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Vintage Brown Duffle Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-07",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-07",
    price: 2900,
    product_name: "Duffle Bag",
    short_description: "Brown Duffle Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Duffle Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Duffle Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Duffle Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-08",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-08",
    price: 3850,
    product_name: "Trolly Bag",
    short_description: "Brown  Trolly Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown  Trolly Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown  Trolly Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown  Duffle Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-09",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-09",
    price: 2950,
    product_name: "Trolly Bag",
    short_description: "Tan  Trolly Bag(M)",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Tan  Trolly  Bag(M)-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Tan  Trolly  Bag(M)-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Tan  Trolly  Bag(M)-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-10",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-10",
    price: 2300,
    product_name: "Executive Bag",
    short_description: "Tan Executive Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Tan Executive Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Tan Leather Executive Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Tan Leather Executive Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-11",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-11",
    price: 2300,
    product_name: "Executive Bag",
    short_description: "Brown  Executive Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Executive  Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Executive  Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Executive  Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-12",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-12",
    price: 2300,
    product_name: "Executive Bag",
    short_description: "Dark Brown Executive Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Dark Brown Executive Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Dark Brown Executive Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Dark Brown Executive Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-13",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-13",
    price: 2300,
    product_name: "Executive Bag",
    short_description: "Grey Executive Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Grey Executive Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Grey Executive Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Grey Executive Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-14",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-14",
    price: 2300,
    product_name: "Executive Bag",
    short_description: "Black Executive Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Black Executive Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Black Executive Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Black Executive Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-15",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-15",
    price: 6650,
    product_name: "Suitcase",
    short_description: "Blackberry Suitcase",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Blackberry Suitcase-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Blackberry Suitcase-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Blackberry Suitcase-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-16",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-16",
    price: 6650,
    product_name: "Briefcase",
    short_description: "Brown  Briefcase",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown  Briefcase-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown  Briefcase-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown  Briefcase-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-17",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-17",
    price: 2520,
    product_name: "Messenger Bag",
    short_description: "Navy Blue Messenger Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Navy Blue Messenger Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Navy Blue Messenger Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Navy Blue Messenger Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-18",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-18",
    price: 2520,
    product_name: "Messenger Bag",
    short_description: "Green Messenger Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Green Messenger Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Green Messenger Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Green Messenger Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-19",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-19",
    price: 3200,
    product_name: "Laptop Bag",
    short_description: "Black Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Black Laptop Bag MD-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Black Laptop Bag MD-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Black Laptop Bag MD-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-20",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-20",
    price: 3200,
    product_name: "Laptop Bag",
    short_description: "Blackberry Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Blackberry Laptop Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Blackberry Laptop Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Blackberry Laptop Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-21",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-21",
    price: 3450,
    product_name: "Laptop Bag",
    short_description: "Black Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Black Laptop Bag (L)-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Black Laptop Bag (L)-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Black Laptop Bag (L)-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-22",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-22",
    price: 3690,
    product_name: "Laptop Bag",
    short_description: "Blue Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Blue Laptop Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Blue Laptop Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Blue Laptop Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-23",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-23",
    price: 4850,
    product_name: "Laptop Bag",
    short_description: "Brown Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Laptop Bag(MD)-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Laptop Bag(MD)-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Laptop Bag(MD)-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-24",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-24",
    price: 1950,
    product_name: "Laptop Bag",
    short_description: "Brown TP Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown TP Laptop Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown TP Laptop Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown TP Laptop Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-25",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-25",
    price: "",
    product_name: "Laptop Bag",
    short_description: "Rich Brown Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Rich Brown Laptop Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Rich Brown Laptop Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Rich Brown Laptop Bag-.3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-26",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-26",
    price: 8755,
    product_name: "Trolly Bag",
    short_description: "Brown Trolly Travel Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Trolly Travel Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Trolly Travel Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Trolly Travel Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-27",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-27",
    price: 6500,
    product_name: "Trolly Bag",
    short_description: "Tan Trolly Travel Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Tan Trolly Travel Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Tan Trolly Travel Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Tan Trolly Travel Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-28",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-28",
    price: 6500,
    product_name: "Trolly Bag",
    short_description: "Blackberry Trolly Travel Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Blackberry Trolly Travel Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Blackberry Trolly Travel Bag-2.jpg",
          },
          {
            url: "/assets/other-products/leather products/Blackberry Trolly Travel Bag-3.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-29",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-29",
    price: 2650,
    product_name: "Office Bag",
    short_description: "Office Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Black Office Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Black Office Bag-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Black Office Bag-1.jpg",
          },
        ],
      },
    ],
  },*/
  {
    master_id: "LP-30",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-30",
    price: 450,
    product_name: "Remote Car Keychains",
    short_description: "Multi- Colour  Car Keychains",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Multi- Colour  Car Keychains-1.jpg",
          },
          {
            url: "/assets/other-products/leather products/Multi- Colour  Car Keychains-2.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-31",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-31",
    price: 980,
    product_name: "Wallet",
    short_description: "Brown Wallet without coin holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Wallet without coin holder.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-32",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-32",
    price: "1200&1600",
    product_name: "Wallet Box",
    short_description: "Brown Wallet Box",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Wallet Box 2&4.jpg",
          },
          {
            url: "/assets/other-products/leather products/Brown Wallet Box 4&6.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-33",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-33",
    price: 832,
    product_name: "Wallet",
    short_description: "Brown Wallet with extra Flop",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Wallet with extra Flop.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-34",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-34",
    price: 682,
    product_name: "Wallet",
    short_description: "Brown Wallet with coin holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Wallet with coin holder.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-35",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-35",
    price: 2450,
    product_name: "Sling Bag",
    short_description: "Brown Unisex Sling Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Unisex Sling Bag.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-36",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-36",
    price: 425,
    product_name: "Card Holder",
    short_description: "Brown Visiting Card Holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Visiting Card Holder.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-37",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-37",
    price: 4800,
    product_name: "Trolly Bag",
    short_description: "Brown Trolly Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Trolly Bag(S).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-38",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-38",
    price: 850,
    product_name: "Wallet",
    short_description: "Brown Trifold Wallet",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Trifold Wallet.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-39",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-39",
    price: 1200,
    product_name: "Spectacle cover",
    short_description: "Brown Leather Spectacle Cover",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Leather Spectacle Cover.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-40",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-40",
    price: 1750,
    product_name: "Tab Sleeve",
    short_description: "Brrown Leather Tab Sleeve",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brrown Leather Tab Sleeve.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-41",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-41",
    price: 850,
    product_name: "Stationary Pouch",
    short_description: "Leather Stationary Pouch",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Leather Stationary Pouch.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-42",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-42",
    price: 1300,
    product_name: "Watch Pouch",
    short_description: "Rollup Watch Pouch",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Rollup Watch Pouch.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-43",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-43",
    price: 1861,
    product_name: "Sling Bag",
    short_description: "Brown Small Sling Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Small Sling Bag.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-44",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-44",
    price: 850,
    product_name: "Mouse Pad",
    short_description: "Premium Leather Mouse Pad",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Premium Leather Mouse Pad.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-45",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-45",
    price: 3900,
    product_name: "Premium Laptop Bag",
    short_description: "Brown Leather Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Leather Laptop Bag.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-46",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-46",
    price: 3800,
    product_name: "Premium Duffle Bag",
    short_description: "Brown Leather Duffle Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Leather Duffle Bag.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-47",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-47",
    price: 650,
    product_name: "Pen Stand",
    short_description: "Leather Pen Stand",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Leather Pen Stand.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-48",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-48",
    price: 550,
    product_name: "Passport Holder",
    short_description: "Brown Leather Passport Holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Leather Passport Holder.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-49",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-49",
    price: 330,
    product_name: "Page mark",
    short_description: "Page Mark (3 items)",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Page Mark (3 items).jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-50",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-50",
    price: 950,
    product_name: "Paper Folder",
    short_description: "Grey Paper Folder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Grey Paper Folder.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-51",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-51",
    price: 1400,
    product_name: "Organizer",
    short_description: "Brown Leather Organizer",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Leather Organizer.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-52",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-52",
    price: 530,
    product_name: "Mobile Charge Holder",
    short_description: "Tan Mobile Charge Holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Tan Mobile Charge Holder.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-53",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-53",
    price: 1200,
    product_name: "Makeup Pouch",
    short_description: "Brown Leather Makeup Pouch",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Leather Makeup Pouch.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-54",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-54",
    price: 1750,
    product_name: "Macbook Sleeve",
    short_description: "Multi Colour Macbook Sleeve",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Multi Colour Macbook Sleeve.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-55",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-55",
    price: 2595,
    product_name: "Ladies Handbag",
    short_description: "Ladies Handbag- Multi colour",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Ladies Handbag- Multi colour.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-56",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-56",
    price: 2500,
    product_name: "Laptop Bag",
    short_description: "3 Compartment Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/3 Compartment Laptop Bag.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-57",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-57",
    price: 1950,
    product_name: "Laptop Bag",
    short_description: "2 Compartment Laptop Bag",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/2 Compartment Laptop Bag.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-58",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-58",
    price: 250,
    product_name: "Ladies Coin purse",
    short_description: "Ladies Coin purse- Multi colour",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Ladies Coin purse- Multi colour.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-59",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-59",
    price: 1070,
    product_name: "Ladies Clutch",
    short_description: "Ladies Clutch- Multi Colour",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Ladies Clutch- Multi Colour.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-60",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-60",
    price: 2850,
    product_name: "Gym Bag",
    short_description: "Gym Bag- Multi Colour",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Gym Bag- Multi Colour.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-61",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-61",
    price: 1155,
    product_name: "Gift Wallet and Card Holder",
    short_description: "Gift Wallet and Card Holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Gift Wallet and Card Holder.jpg",
          },
        ],
      },
    ],
  },
/*   {
    master_id: "LP-62",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-62",
    price: 1150,
    product_name: "Pen &Wallet Combo",
    short_description: "Pen &Wallet Combo",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Pen &Wallet Combo.jpg",
          },
        ],
      },
    ],
  }, */
  {
    master_id: "LP-63",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-63",
    price: 1250,
    product_name: "Gift Wallet and Belt",
    short_description: "Gift Wallet and Belt",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Gift Wallet and Belt.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-64",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-64",
    price: 1300,
    product_name: "Gift Organizer &V Card",
    short_description: "Gift Organizer &V Card",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Gift Organizer &V Card.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-65",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-65",
    price: 1990,
    product_name: "Gift Organizer,V Card& Wallet",
    short_description: "Gift Organizer,V Card& Wallet",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Gift Organizer,V Card& Wallet.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-66",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-66",
    price: 1480,
    product_name: "Earbud Case",
    short_description: "Earbud Case",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/leather products/Earbud Case.jpg" },
        ],
      },
    ],
  },
  {
    master_id: "LP-67",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-67",
    price: 950,
    product_name: "Dairy Cover",
    short_description: "Dairy Cover with leaf and Card holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Dairy Cover with leaf and Card holder.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-68",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-68",
    price: 1300,
    product_name: "Dairy",
    short_description: "Leather dairy with Handmade paper",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Leather dairy with Handmade paper.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-69",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-69",
    price: 950,
    product_name: "Cheque Leaf Holder",
    short_description: "Cheque Leaf Holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Cheque Leaf Holder.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "LP-70",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-70",
    price: 803,
    product_name: "Credit Card holder",
    short_description: "Credit Card holder",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Credit Card holder.jpg",
          },
        ],
      },
    ],
  },
/*   {
    master_id: "LP-71",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-71",
    price: 6650,
    product_name: "Briefcase",
    short_description: "Brown Briefcase",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Brown Briefcase.jpg",
          },
        ],
      },
    ],
  }, */
  /*   {
    master_id: "LP-72",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-72",
    price: 950,
    product_name: "Belt",
    short_description: "Black,Brown Leather Belts",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/leather products/Black,Brown Leather Belts.jpg",
          },
        ],
      },
    ],
  }, */
  {
    master_id: "LP-73",
    category: "leather_products",
    search_category: "leather_products",
    category_link: "Premium Leather Products",
    master_code: "LP-73",
    price: 2584,
    product_name: "Backpack",
    short_description: "Tan Backpack",
    material: "Leather",
    quantity: 5,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/leather products/Tan Backpack.jpg" },
        ],
      },
    ],
  },
];

LeatherProducts.forEach((item) => {
  const newPrice = (item.price * 0.044).toFixed(2);
  // Updating the price without currency
  item.price = newPrice;
});

export default LeatherProducts;
