import React from "react";
import "./terms-and-conditions.scss";
import HeaderOne from "../../wrappers/header/HeaderOne";
import FooterOne from "../../wrappers/footer/FooterOne";

const DeliveryPolicy = () => {
  return (
    <>
      <HeaderOne />
      <div className="terms-and-condition-genie-arabia">
        <div className="static-content-area">
          <h1>Delivery Policy</h1>
          <ul>
            <li>
              Delivery is available across the world. Shipping charges and delivery times may vary based on the destination.
            </li>
            <li>
              Orders will be processed and shipped within 2 to 5 business days after confirmation.
            </li>
            <li>
              Once your order is shipped, you will receive a confirmation email with tracking information.
            </li>
            <li>
              We strive to deliver your order within the estimated delivery times. However, delays may occur due to unforeseen circumstances such as weather conditions, customs delays, or carrier issues.
            </li>
            <li>
              In case of any issues with your delivery, please contact our customer support team at sales@geniearabia.com.
            </li>
          </ul>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default DeliveryPolicy;
