import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import ProductDescriptionInfoNew from "../../components/product/ProductDescriptionInfoNew";
import { Breadcrumb } from "antd";
import { useState } from "react";
import ProductImageGalleryNew from "../../components/product/ProductImageGalleryNew";
import { Link } from "react-router-dom";

const ProductImageDescriptionNew = ({ spaceTopClass, spaceBottomClass, product }) => {
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);

  const { compareItems } = useSelector((state) => state.compare);
  const compareItem = compareItems.find(item => item.id === product.master_id);
  const [variantIndex, setVariantIndex] = useState(0)

  const breadcrumbItems = [
    {
      title: <span className="breadcrumb-item bread-active">Home</span>,
      href: "/",
    },
    {
      title: (
        <span className="breadcrumb-item bread-active">
          <Link to={"/Made-In-India-Products"} state={product.search_category} >{product.category_link}</Link>
        </span>
      ),
    },
    
    {
      title: <span className="breadcrumb-item bread-active">Made In India Products</span>,
      href: "https://geniearabia.com/Products-list",
    }
  ];

  const filteredBreadcrumbItems = breadcrumbItems.filter(
    (item) => item.title !== undefined
  );

  return (
    <div className={clsx("shop-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        <div className="row">
          <Breadcrumb
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginBottom: "20px",
            }}
            separator="/"
            items={filteredBreadcrumbItems}
          />
          <div className="col-lg-6 col-md-6">
            <ProductImageGalleryNew
              product={product}
              variantIndex={variantIndex}
            />
          </div>
          <div className="col-lg-6 col-md-6">
          
            <ProductDescriptionInfoNew
              product={product}
              discountedPrice={0}
              currency={currency}
              finalDiscountedPrice={0}
              finalProductPrice={0}
              cartItems={cartItems}
              wishlistItem={null}
              compareItem={compareItem}
              setVariantIndex={setVariantIndex}
              variantIndex={variantIndex}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageDescriptionNew.propTypes = {
  galleryType: PropTypes.string,
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default ProductImageDescriptionNew;
