// import React from 'react'
// import { Provider } from 'react-redux'
// import App from './App'
// import { store } from './store/store'
// import PersistProvider from './store/providers/persist-provider'
// import 'animate.css'
// import 'swiper/swiper-bundle.min.css'
// import 'yet-another-react-lightbox/styles.css'
// import 'yet-another-react-lightbox/plugins/thumbnails.css'
// import './assets/scss/style.scss'

// import { ConfigProvider } from 'antd'
// import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'
// import { hydrate, render } from 'react-dom'

// const container = document.getElementById('root')
// const rootElement = document.getElementById('root')

// if (container.hasChildNodes()) {
//   hydrate(
//     <Provider store={store}>
//       <PersistProvider>
//         <ConfigProvider
//           theme={{
//             token: {
//               fontFamily: 'Poppins',
//             },
//           }}
//         >
//           <div
//             style={{
//               background: '#fafafa50',
//             }}
//           >
//             <DndProvider backend={HTML5Backend}>
//               <App />
//             </DndProvider>
//           </div>
//         </ConfigProvider>
//       </PersistProvider>
//     </Provider>,
//     rootElement
//   )
// } else {
//   render(
//     <Provider store={store}>
//       <PersistProvider>
//         <ConfigProvider
//           theme={{
//             token: {
//               fontFamily: 'Poppins',
//             },
//           }}
//         >
//           <div
//             style={{
//               background: '#fafafa50',
//             }}
//           >
//             <DndProvider backend={HTML5Backend}>
//               <App />
//             </DndProvider>
//           </div>
//         </ConfigProvider>
//       </PersistProvider>
//     </Provider>,
//     rootElement
//   )
// }

import React from "react";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import "animate.css";
import "swiper/swiper-bundle.min.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
import { createRoot } from "react-dom/client";
import { ConfigProvider } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";


const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <PersistProvider>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Poppins",
          },
        }}
      >
        <div
          style={{
            background: "#fafafa50",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <App />
          </DndProvider>
        </div>
      </ConfigProvider>
    </PersistProvider>
  </Provider>,
  rootElement
);
