const PensAndProductsDiaryProducts = [
  {
    master_id: "EXCULSO-1",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "VBLDC-02",
    price: 15.02,
    product_name: "X-02 Blue Vegan Diary Pen Giftset",
    short_description: "80 gsm natural white paper (Non Dated Organizer)",
    material: "Vegan Leather",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/X-02 Vegan Blue Diary Pen Combo-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-02 Vegan Blue Diary Pen Combo-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-02 Vegan Blue Diary Pen Combo-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-02 Vegan Blue Diary Pen Combo-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-2",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "VBRDC-02",
    price: 14.98,
    product_name: "X-02 Brown Vegan Diary Pen Giftset",
    short_description: "80 gsm natural white paper (Non Dated Organizer)",
    material: "Vegan Leather",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/X-02 Vegan Brown Diary Pen Combo-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-02 Vegan Brown Diary Pen Combo-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-02 Vegan Brown Diary Pen Combo-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-02 Vegan Brown Diary Pen Combo-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-3",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ACC-999",
    price: 13.12,
    product_name: "Arteca-999 Coffee Combo",
    short_description: "Ballpen Roller Pen Combo",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-999 Coffee Ball Pen Roller Pen Giftset.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-4",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "AGC-999",
    price: 13.12,
    product_name: "Arteca-999 Grey Combo",
    short_description: "Ballpen Roller Pen Combo",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-999 Grey Ball Pen Roller Pen Giftset.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-5",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ACC-481",
    price: 13.2,
    product_name: "Arteca-481 Copper Combo",
    short_description: "Ballpen Roller Pen Combo",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-481 Copper Ball Pen Roller Pen Giftset.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-6",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "MBC-227",
    price: 8.14,
    product_name: "Monrik-227 Black Combo",
    short_description: "Ballpen Roller Pen Combo",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Monrik-227 Ball Pen Roller Pen Giftset.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-7",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ACB-999",
    price: 5.67,
    product_name: "Arteca-999 Coffee BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-999 Coffee Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Coffee Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Coffee Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-8",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ACR-999",
    price: 7.45,
    product_name: "Arteca-999 Coffee RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-999 Coffee Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Coffee Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Coffee Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Coffee Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-9",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "AGB-999",
    price: 5.67,
    product_name: "Arteca-999 Grey BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-999 Grey Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Grey Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Grey Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-10",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "AGR-999",
    price: 7.45,
    product_name: "Arteca-999 Grey RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-999 Grey Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Grey Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Grey Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-999 Grey Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-11",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ACB-481",
    price: 5.82,
    product_name: "Arteca-481 Copper BP",
    short_description: "Ballpen with 1.0 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-481 Copper Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-481 Copper Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-481 Copper Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-12",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ACR-481",
    price: 7.38,
    product_name: "Arteca-481 Copper RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-481 Copper Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-481 Copper Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-481 Copper Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-481 Copper Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-13",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ASB-261",
    price: 6.36,
    product_name: "Arteca-261 Satin BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-261 Satin Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-261 Satin Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-261 Satin Ballpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-261 Satin Ballpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-14",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ARR-369",
    price: 6,
    product_name: "Arteca-369 Red RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-369 Red Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Red Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Red Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Red Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-15",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "AYR-369",
    price: 6,
    product_name: "Arteca-369 Yellow RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-369 Yellow Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Yellow Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Yellow Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Yellow Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-16",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "AVR-369",
    price: 6,
    product_name: "Arteca-369 Violet RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Arteca-369 Violet Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Violet Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Violet Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Arteca-369 Violet Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-17",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "MBR-101",
    price: 5.89,
    product_name: "Monrik-101 Black RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Monrik-101 Black Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-101 Black Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-101 Black Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-101 Black Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-18",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "MSR-101",
    price: 5.89,
    product_name: "Monrik-101 Silver RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Monrik-101 Silver Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-101 Silver Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-101 Silver Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-101 Silver Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-19",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "JBKR-103",
    price: 4.47,
    product_name: "Jenico-103 Black Marble RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Jenico-103 Black Marble Roller Pen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Black Marble Roller Pen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Black Marble Roller Pen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Black Marble Roller Pen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-20",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "JBLR-103",
    price: 4.47,
    product_name: "Jenico-103 Red Marble RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Jenico-103 Red Marble Roller Pen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Red Marble Roller Pen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Red Marble Roller Pen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Red Marble Roller Pen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-21",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "JRDR-103",
    price: 4.47,
    product_name: "Jenico-103 Blue Marble RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Jenico-103 Blue Marble Roller Pen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Blue Marble Roller Pen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Blue Marble Roller Pen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-103 Blue Marble Roller Pen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-22",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "EGR-9720",
    price: 4.36,
    product_name: "Elite-9720 L.Grey RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Elite-9720 Light Grey Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Light Grey Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Light Grey Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Light Grey Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-23",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "EBR-9720",
    price: 4.36,
    product_name: "Elite-9720 Blue RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Elite-9720 Blue Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Blue Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Blue Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Blue Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-24",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "ERR-9720",
    price: 4.36,
    product_name: "Elite-9720 Red RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Elite-9720 Red Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Red Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Red Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Elite-9720 Red Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-25",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "MBB-227",
    price: 3.49,
    product_name: "Monrik-227 Black BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Monrik-227 Black Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-227 Black Ballpen-2.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-26",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "MBR-227",
    price: 4.65,
    product_name: "Monrik-227 Black RB",
    short_description: "Rollerpen with 0.7 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Monrik-227 Black Rollerpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-227 Black Rollerpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-227 Black Rollerpen-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/Monrik-227 Black Rollerpen-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-27",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "JBB-102",
    price: 2.84,
    product_name: "Jenico-102 Black BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Jenico-102 Black Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-102 Black Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-102 Black Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-28",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "JMBB-102",
    price: 2.84,
    product_name: "Jenico-102 Metallic Blue BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Jenico-102 Metallic Blue Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-102 Metallic Blue Ballpen-2.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-29",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "JMOB-102",
    price: 2.84,
    product_name: "Jenico-102 Metallic Orange BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Jenico-102 Metallic Orange Ballpen-.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-102 Metallic Orange Ballpen-2.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-30",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "JGB-102",
    price: 3.38,
    product_name: "Jenico-102 Gold BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Brass Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Jenico-102 Gold Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-102 Gold Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Jenico-102 Gold Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-31",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "SBKB-1",
    price: 2.51,
    product_name: "Shivino Touch Black BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Black Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Black Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Black Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-32",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "SBLB-1",
    price: 2.51,
    product_name: "Shivino Touch Blue BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Blue Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Blue Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Blue Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-33",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "SSLB-1",
    price: 2.51,
    product_name: "Shivino Touch Silver BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Silver Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Silver Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino Mobile Touch Silver Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-34",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "SGB-555",
    price: 2.29,
    product_name: "Shivino-555 Green BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Shivino-555 Green Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino-555 Green Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino-555 Green Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-35",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "SBB-555",
    price: 2.29,
    product_name: "Shivino-555 Metallic Blue BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Shivino-555 Metallic Blue Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino-555 Metallic Blue Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino-555 Metallic Blue Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-36",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "SCB-555",
    price: 2.29,
    product_name: "Shivino-555 Coffee BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Shivino-555 Coffee Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino-555 Coffee Ballpen-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino-555 Coffee Ballpen-3.jpg",
          },
          
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-37",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "SWRB-1",
    price: 1.85,
    product_name: "Shivino White Ring BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Shivino White Ring Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino White Ring Ballpen-2.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-38",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "SRRB-1",
    price: 1.85,
    product_name: "Shivino Red Ring BP",
    short_description: "Ballpen with 0.5 tip size",
    material: "Aluminium Metal",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/Shivino Red Ring Ballpen-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/Shivino Red Ring Ballpen-2.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-39",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "VBLD-02",
    price: 10,
    product_name: "Blue Vegan Leather Diary (CEO Collection)",
    short_description: "80 gsm natural white paper (Non Dated Organizer)",
    material: "Vegan Leather",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/excluso/X-01 Blue Paper Diary-1.jpg" },
          { url: "/assets/other-products/excluso/X-01 Blue Paper Diary-2.jpg" },
          { url: "/assets/other-products/excluso/X-01 Blue Paper Diary-3.jpg" },
          { url: "/assets/other-products/excluso/X-01 Blue Paper Diary-4.jpg" },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-40",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "VBRD-02",
    price: 9.35,
    product_name: "Brown Vegan Leather Diary (CEO Collection)",
    short_description: "80 gsm natural white paper (Non Dated Organizer)",
    material: "Vegan Leather",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          {
            url: "/assets/other-products/excluso/X-01 Brown Paper Diary-1.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-01 Brown Paper Diary-2.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-01 Brown Paper Diary-3.jpg",
          },
          {
            url: "/assets/other-products/excluso/X-01 Brown Paper Diary-4.jpg",
          },
        ],
      },
    ],
  },
  {
    master_id: "EXCULSO-41",
    category: "pens_and_diary",
    search_category: "pens_and_diary",
    category_link: "Pens & Diary",
    master_code:  "VMWD-1",
    price: 10,
    product_name: "Black Vegan Leather Mobile Wallet Diary",
    short_description: "80 gsm natural white paper (Non Dated Organizer)",
    material: "Vegan Leather",
    quantity: 10,
    variants: [
      {
        digital_assets: [
          { url: "/assets/other-products/excluso/X-02 Blue Vegan Diary-1.jpg" },
          { url: "/assets/other-products/excluso/X-02 Blue Vegan Diary-2.jpg" },
          { url: "/assets/other-products/excluso/X-02 Blue Vegan Diary-3.jpg" },
          { url: "/assets/other-products/excluso/X-02 Blue Vegan Diary-4.jpg" },
        ],
      },
    ],
  },
];
export default PensAndProductsDiaryProducts;
