import cogoToast from 'cogo-toast';
import { userLoggedIn } from '../../helpers/user';
import { getCall } from '../../api/apiService';
const { createSlice } = require('@reduxjs/toolkit');


export async function fetchWishlistItems(dispatch) {
    if(userLoggedIn()){
        try {
            const response = await getCall("/Wishlist");
            if (!!response) {
                dispatch(setWishlistItems(response.wishList));
            } else {
                dispatch(setWishlistItems([]));
            }
        } catch (error) {
            dispatch(setWishlistItems([]));
        }
    }
}

const wishlistSlice = createSlice({
    name: "wishlist",
    initialState: {
        wishlistItems: []
    },
    reducers: {
        addToWishlist(state, action) {
            state.wishlistItems.push(action.payload);
            cogoToast.success("Added To wishlist", {position: "bottom-left"});
        },
        deleteFromWishlist(state, action){
            state.wishlistItems = state.wishlistItems.filter(item => item.id !== action.payload);
            cogoToast.error("Removed From Wishlist", {position: "bottom-left"});
        },
        deleteAllFromWishlist(state){
            state.wishlistItems = []
        },
        setWishlistItems(state, action) {
            state.wishlistItems = action.payload;
        },
    },
});

export const { addToWishlist, deleteFromWishlist, deleteAllFromWishlist , setWishlistItems } = wishlistSlice.actions;
export default wishlistSlice.reducer;
