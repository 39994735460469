const { createSlice } = require("@reduxjs/toolkit");

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: [],
  },
  reducers: {
    setuser(state, action) {
      state.user = action.payload;
    },
    updateAddress(state, action) {
      state.user.address = action.payload;
    },
  },
});

export const { setuser, updateAddress } = userSlice.actions;
export default userSlice.reducer;
