import React from "react";
import HeaderOne from "../../wrappers/header/HeaderOne";
import FooterOne from "../../wrappers/footer/FooterOne";
import SEO from "../seo";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderOne />
      <SEO
        titleTemplate="Privacy Policy"
        description="Privacy Policy"
      />
      <div className="terms-and-condition-genie-arabia">
        <div className="static-content-area">
          <ul>
            <li>
              Geniearabia.com takes the privacy of your information seriously.
              This privacy policy (“Privacy Policy”) applies to your use of the
              domain name www.sample.in, owned, and operated by sample. This
              policy describes the use for collecting your personal information,
              the entities with whom we may share it, and the steps taken to
              safeguard your data. Additionally, it clarifies your rights and
              options concerning your personal information, as well as provides
              information on how to reach us regarding our privacy procedures.
            </li>
            <li>
              It is recommended that you thoroughly review this Privacy Notice
              before utilizing or accessing any of our products and/or services.
            </li>
            <li>
              We have implemented security practices in line with the
              information assets we protect and the nature of our business.
              While we strive to maintain security under industry standards, the
              inherent vulnerabilities of the internet prevent us from
              guaranteeing the complete security of transmitted information.
            </li>
            <li>
              This Privacy Policy applies to all Users/Sellers using this
              website. Therefore, the acceptance of this Privacy Policy is a
              must for every User accessing the Website and other services
              offered by sample.in. Users must read and understand the outlined
              provisions before providing any Sensitive Personal Data or
              Information to sample.in. Failure to do so requires an immediate
              departure from the website.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h1>Collection of Information</h1>
          <ul>
            <li>
              The User acknowledges that the Company/Website may automatically
              track specific details about the User and their activities on the
              website. It is understood by the User that this information is
              utilized for internal research into user demographics, interests,
              and behavior, allowing the Company/Website to gain a deeper
              understanding and better cater to the preferences of its users.
              The User is explicitly informed that such information may
              encompass the URL of the website visited before accessing the
              current site, and the subsequent URLs visited (whether or not they
              are part of the Website).
            </li>
            <span>What we collect</span>
            <li>
              <h5>Personal information:</h5>
              For a better experience while using our Service, we may require
              you to provide us with certain personally identifiable
              information, including but not limited to your name, phone number,
              and postal address. The information that we collect will be used
              to contact or identify you.
            </li>
            <li>
              <h5>Log Data/Information</h5>
              We want to inform you that whenever you visit our Service, we
              collect information that your browser sends to us. This data may
              include information such as your computer’s Internet Protocol
              (“IP”) address, browser version, pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, and other statistics.
            </li>
            <li>
              <h5>Privacy Implications User-Generated Content:</h5>
              If a User decides to post messages, reviews, or feedback on any
              section of the website, such as message boards, chat rooms, and
              other message areas, the User acknowledges that all such
              information provided or uploaded will be collected and stored by
              us indefinitely and may be utilized for purposes such as resolving
              disputes, offering customer support, troubleshooting problems,
              etc. Furthermore, if required, this information may be disclosed
              to judicial or governmental authorities of the necessary
              jurisdiction or used by the Company/Website under applicable laws.
            </li>
            <li>
              <h5>Other information:</h5>
              The User is aware that any and every information concerning the
              User collected by the corporate, whether or indirectly provided by
              the User to the Company/Website, including but not limited to
              non-public correspondence like emails or letters, feedback from
              other users or third parties regarding the User’s activities or
              postings on the web site, etc., could also be collected and
              compiled by the Company/Website into a file/folder specifically
              created for / allotted to the User, and therefore the User hereby
              expressly consents to an equivalent.
            </li>
            <li>
              <h5>Automatic Data Collection:</h5>
              The User should be aware that the website uses data collection
              devices like cookies on certain pages of the website to assist in
              analyzing the website flow, measure promotional effectiveness, and
              promote trust and safety, in which certain features of the website
              are only available through the utilization of such cookies. While
              the User is liberal to decline the Website’s cookies if the User’s
              browser permits, the User may consequently be unable to use
              certain features on the Website. The User is aware that the
              website stores both permanent and temporary cookies within the
              User’s computer’s disk drive and browser, and hereby explicitly
              gives consent to this action.
              <br />
              <br />
              Additionally, the User is aware that he/she might encounter
              ‘cookies’ or other similar devices on certain pages of the website
              that are placed by third parties or affiliates of the
              Company/Website. The User expressly agrees and acknowledges that
              the Company/Website doesn’t control the utilization of such
              cookies/other devices by third parties, and that the
              Company/Website is in no way liable for an equivalent, which the
              User assumes any and everyone risks during this regard.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Data Sharing:</h6>
          <ul>
            <li>
              <h5>Service Providers:</h5>
              We may employ third-party companies and individuals to process
              information for a specific purpose under a written contract and
              also for the following reasons:
              <ol>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf</li>
                <li>To assist us in analyzing how our Service is used.</li>
                <li>
                  To inform our Service users that these third parties have
                  access to your personal information. The reason is to perform
                  the tasks assigned to them on our behalf.
                </li>
                <li>
                  However, these third parties are obligated not to disclose or
                  use the information for any other purpose.
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Transaction Security</h6>
          <ul>
            <li>
            Transactions on our website are secure and guarded. Any information entered by the User when transacting on the website is encrypted to guard the User against unintentional disclosure to third parties. The User’s credit and open-end credit information are not asked, received, stored by, or retained by the corporate / Website in any manner. This information is supplied by the User to the relevant payment gateway which is permitted to handle the knowledge provided and is compliant with the regulations and requirements of varied banks, institutions, and payment franchisees that it’s related to.
            </li>
            <li>
            All the information collected/ stored under this Privacy Policy and Terms of Use is maintained by sample.in electronic form on its equipment, and on the equipment of its employees. User Information may also be converted to physical form from time to time. Regardless of the manner of storage, sample.in will keep all User Information confidential and will use/ disclose it only in the manner specified under the Privacy Policy and Terms of Use.
            </li>
            <li>
            Part of the functionality of the Website is assisting the Sellers to maintain and organize such information to affect the sale and purchase of products. sample.in may, therefore, retain and submit all such records to the appropriate authorities, or to Sellers who request access to such information. sample.in will also ensure that the User Information is not kept for a longer period than is required for the purposes for which it is collected or as required under any applicable law.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Links to third-party advertisements</h6>
          <ul>
            <li>
            The links to third-party advertisements, third-party websites, or any third-party electronic services may be provided on the Website which is operated by third parties and is not controlled by, or affiliated to, or associated with sample.in unless expressly specified on the Website.
            </li>
            <li>
            If you access any such Third-Party Links, we request you review the website’s privacy policy. We are not responsible for the policies or practices of Third-Party Links.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Children’s Privacy</h6>
          <ul>
            <li>
            Our Services do not address anyone under the age of 18. We do not knowingly collect personally identifiable information from children under 18. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to take the necessary actions.

            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Changes in the privacy policy</h6>
          <ul>
            <li>
              geniearabia.com may update this Privacy Policy at any time, with or without advance notice. In the event, there are significant changes in the way sample.in handles User’s Personal Information. sample.in will display a notice on the Website or send Users an email. If a User uses the Service after notice of changes has been sent to such User or published on the Website, with his/ her consent to the changed policies.
            </li>
          </ul>
        </div>
        <div className="static-content-area">
          <h6>Contact Us</h6>
          <ul>
            <li>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to mail us at <a href="mailto:sales@geniearabia.com">sales@geniearabia.com</a>. This Privacy Policy page was created at
            </li>
          </ul>
        </div>
      </div>
      <FooterOne />
    </>
  );
};

export default PrivacyPolicy;
