import React, { Fragment, useState, /* useEffect */ } from "react";
import clsx from "clsx";
import { Card } from "antd";
import { Link, useNavigate } from "react-router-dom";
import {
  addEllipsis,
  capitalizeWords,
} from "../helpers/product";
import HeaderOne from "../wrappers/header/HeaderOne";
import FooterOne from "../wrappers/footer/FooterOne";
import { useLocation } from "react-router-dom";
// import axios from "axios";
import PensAndProductsDiaryProducts from "./PensAndProductsDiaryProducts";
import CopperBottles from "./CopperBottles"
import LeatherProducts from "./LeatherProducts";
import WhiteElements from "./WhiteElements";

function MadeIndiaProductsShop() {

  const location = useLocation();
  const search_category = location.state;
  const navigate = useNavigate();
  const [imageLoading, setImageLoading] = useState(true);

  function handleImageLoad() {
    setImageLoading(false);
  }

/*   const [products, setProducts] = useState([]); */

/*   useEffect(() => {
    async function fetchProducts() {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SEO_API_BASE}/Products/${search_category}`);
        setProducts(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }

    fetchProducts();
  }, [search_category]); */

  let allProducts =  [];
  allProducts = allProducts.concat(PensAndProductsDiaryProducts,CopperBottles,LeatherProducts,WhiteElements);
  const products = allProducts.filter(product => product.category === search_category);
  

  return (
    <div>
      <HeaderOne></HeaderOne>
      <div className="shop-bottom-area mt-35">
        <div
          className={clsx("row", "grid three-column")}
          style={{ margin: "40px" }}
        >
          {products.map((product) => (
            <div
              key={product.master_id}
              className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-sm-4 col-6"
            >
              <Fragment>
                <div className={clsx("product-wrap", "mb-25")}>
                  <Card loading={!product}>
                    <div className="product-img" title="Click To View">
                    <Link
                        to={{
                          pathname: process.env.PUBLIC_URL + `/Product-Details`,
                        }}
                        state={product.master_id}
                      >
                        {imageLoading && (
                          <img
                            alt="dash-circle IMG"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/loaders/circle/dash-circle.gif"
                            }
                            height={150}
                            width={15}
                            style={{
                              transform: "scale(0.2)",
                            }}
                          />
                        )}
                        <img
                          className="default-img"
                          src={product?.variants[0]?.digital_assets[0]?.url}
                          alt="default-img"
                          onLoad={handleImageLoad}
                        />
                        {product?.variants[0]?.digital_assets?.length > 1 ? (
                          <img
                            className="hover-img"
                            src={product?.variants[0]?.digital_assets[1]?.url}
                            alt="hover-img"
                          />
                        ) : (
                          ""
                        )}
                      </Link>
                    </div>
                    <div
                      className="product-content text-center"
                      onClick={() =>
                        navigate(process.env.PUBLIC_URL + `/Product-Details`)
                      }
                    >
                    <Link
                      to={{
                        pathname: process.env.PUBLIC_URL + `/Product-Details`,
                      }}
                      state={product.master_id}
                    >
                        {product?.master_code} -{" "}
                        {addEllipsis(product?.product_name, 10)}
                        <br />
                        {capitalizeWords(
                          addEllipsis(product?.short_description, 20)
                        )}
                      </Link>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                        className="color-codes"
                      ></div>
                    </div>
                  </Card>
                </div>
              </Fragment>
            </div>
          ))}
        </div>
      </div>
      <FooterOne></FooterOne>
    </div>
  );
}

export default MadeIndiaProductsShop;
