import React, { Fragment } from "react";
import { Breadcrumb } from "antd";
import LayoutOne from "../layouts/LayoutOne";
import ProductImageDescriptionNew from "../wrappers/product/ProductImageDescriptionNew";
import { useLocation } from "react-router-dom";
import PensAndProductsDiaryProducts from "./PensAndProductsDiaryProducts";
import CopperBottles from "./CopperBottles";
import LeatherProducts from "./LeatherProducts";
import WhiteElements from "./WhiteElements";

const ProductDetails = () => {

  const location = useLocation();
  const master_id = location.state;
  let allProducts =  [];
  allProducts = allProducts.concat(PensAndProductsDiaryProducts,CopperBottles,LeatherProducts,WhiteElements);
  const product = allProducts.filter(product => product.master_id === master_id)[0] || null;
  if (product) {
    product.price *= 2;
  }

  if (product.price === 0) {
    product.price= 'Based On the Order';
  }

  const breadcrumbItems = [
    {
      title: (
        <span className="breadcrumb-item" style={{ color: "#fff" }}>
          Home
        </span>
      ),
      href: "/",
    },
    {
      title: (
        <span className="breadcrumb-item" style={{ color: "#fff" }}>
          Shop
        </span>
      ),
      href: "/shop",
    },
    {
      title: (
        <span className="breadcrumb-item" style={{ color: "#fff" }}>
          {product.master_code ?  product.master_code : ''}
        </span>
      ),
      href: "",
    },
  ];

  return (
    <Fragment>
      <LayoutOne headerTop="visible">
        <div
          style={{
            height: "100px",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <img
            alt="product-page-banner Img"
            src={
              process.env.PUBLIC_URL +
              "/assets/breadcrumb/product-page-banner.webp"
            }
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
          />
          <div
            style={{
              position: "absolute",
              background: "#33333350",
              backdropFilter: "blur(3px)",
              width: "100%",
              height: "100%",
              top: "0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Breadcrumb
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#fff",
              }}
              separator=">"
              items={breadcrumbItems}
            />
          </div>
        </div>

        {!!product ? (
          <ProductImageDescriptionNew
            spaceTopClass="pt-70"
            spaceBottomClass="pb-100"
            product={product}
            galleryType="rightThumb"
          />
        ) : (
          <div
            style={{
              minHeight: "50vh",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              alignItems: "center",
              margin: "0 auto",
            }}
          >
            <img
              alt="fetch-products"
              src={
                process.env.PUBLIC_URL + "/assets/loaders/fetch-products.gif"
              }
              height={40}
              width={40}
            />
          </div>
        )}
      </LayoutOne>
    </Fragment>
  );
};

export default ProductDetails;
