import React from "react";
import "./terms-and-conditions.scss";
import HeaderOne from "../../wrappers/header/HeaderOne";
import FooterOne from "../../wrappers/footer/FooterOne";

const CancellationPolicy = () => {
  return (
    <>
      <HeaderOne />
      <div className="terms-and-condition-genie-arabia">
      <div className="static-content-area">
        <h1>Cancellation Policy</h1>
        <ul>
          <li>
            Cancellations will only be accepted if the order has not been processed yet.
          </li>
          <li>
            If you wish to cancel your order, please contact our customer support team immediately at support@geniearabia.com or call us at 1800 309 9955.
          </li>
          <li>
            Once the cancellation is approved, you will receive a confirmation email. If the order has already been processed or shipped, cancellation will not be possible.
          </li>
          <li>
            In case of a successful cancellation, any payments made will be refunded within 7-10 business days to the original mode of payment.
          </li>
          <li>
            G CUBE INTERNATIONAL FZCO reserves the right to cancel any order for any reason, including but not limited to the unavailability of products, errors in pricing, or issues identified by our fraud detection department.
          </li>
          <li>
            If we cancel your order, you will be notified and any payments made will be refunded in full.
          </li>
        </ul>
      </div>
      </div>
      <FooterOne />
      </>
    );
  };

export default CancellationPolicy;
